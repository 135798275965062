import React from 'react'
import { Image } from 'react-bootstrap'
import backgroundVideo from '../../images/tiger.jpg'
import './Banner.css'

const Banner = () => {
  return (
    <>
      <div id='home' className='banner-wrapper text-center'>
        <Image
          className='banner-img'
          src={backgroundVideo}
          alt={'banner-logo'}
        />
        {/* <h1>Let's build your plate together</h1> */}
      </div>
    </>
  )
}

export default Banner
