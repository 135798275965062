import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listBlogDetails } from '../actions/blogActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import parse from 'html-react-parser'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'
import { Button } from 'react-bootstrap'
import moment from 'moment'

const BlogScreen = ({ match }) => {
  const dispatch = useDispatch()

  const blogDetails = useSelector((state) => state.blogDetails)
  const { loading, error, blog } = blogDetails

  useEffect(() => {
    dispatch(listBlogDetails(match.params.id))
  }, [dispatch, match])

  const showBlogCategories = (blog) =>
    blog &&
    blog.categories.map((c, i) => (
      <Button key={i} variant='primary' className='mr-1 ml-1 mt-3'>
        {c}
      </Button>
    ))

  const showBlogTags = (blog) =>
    blog &&
    blog.tags.map((t, i) => (
      <Button key={i} variant='outline-primary' className='mr-1 ml-1 mt-3'>
        {t}
      </Button>
    ))

  return (
    <>
      {loading ? (
        <Loader> Loading...</Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        blog &&
        blog.content && (
          <>
            <div>
              <Meta title={`Blog: ${blog.title}`} />
              {showBlogCategories(blog)}
              {showBlogTags(blog)}
              <h3>{blog.title}</h3>
              <p className='mark ml-1 pt-2 pb-2'>
                By {blog.postedBy.email} | Published{' '}
                {moment(new Date(blog.updatedAt._seconds * 1000)).fromNow()}
              </p>
              {parse(blog.content)}
            </div>
            {/* <div className='container'>
              <h4 className='text-center pt-5 pb-5 h2'>Related blogs</h4>
              <hr />
              <p>show related blogs</p>
            </div>

            <div className='container pb-5'>
              <p>show comments</p>
            </div> */}
            <div className='d-flex justify-content-center'>
              <FacebookShareButton
                url={`https://inshape-dev.uc.r.appspot.com/blog/${blog._id}`}
                quote={blog.title}
                hashtag='#fitness'
              >
                <FacebookIcon className='mx-3' size={36} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={`https://inshape-dev.uc.r.appspot.com/blog/${blog._id}`}
                quote={blog.title}
                hashtag='#fitness'
              >
                <TwitterIcon className='mx-3' size={36} round />
              </TwitterShareButton>
              <WhatsappShareButton
                url={`https://inshape-dev.uc.r.appspot.com/blog/${blog._id}`}
                quote={blog.title}
                hashtag='#fitness'
              >
                <WhatsappIcon className='mx-3' size={36} round />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={`https://inshape-dev.uc.r.appspot.com/blog/${blog._id}`}
                quote={blog.title}
                hashtag='#fitness'
              >
                <LinkedinIcon className='mx-3' size={36} round />
              </LinkedinShareButton>
              <EmailShareButton
                subject={blog.title}
                body={`https://inshape-dev.uc.r.appspot.com/blog/${blog._id} Huh?`}
              >
                <EmailIcon className='mx-3' size={36} round />
              </EmailShareButton>
            </div>
          </>
        )
      )}
    </>
  )
}

export default BlogScreen
