import {
  CART_ADD_ITEM,
  CART_ADD_PLAN,
  CART_CLEAR,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from '../constants/cartConstants'

import firebase from '../firebase/firebase'

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload
      firebase.analytics.logEvent(action.type.toLowerCase())

      const existItem = state.cartItems.find((x) => x.product === item.product)
      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          ),
        }
      } else {
        return { ...state, cartItems: [...state.cartItems, item] }
      }
    case CART_ADD_PLAN:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { ...state, cartItems: action.payload }
    case CART_REMOVE_ITEM:
      firebase.analytics.logEvent(CART_REMOVE_ITEM)
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.product !== action.payload),
      }
    case CART_SAVE_SHIPPING_ADDRESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return {
        ...state,
        shippingAddress: action.payload,
      }
    case CART_SAVE_PAYMENT_METHOD:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case CART_CLEAR:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return {
        ...state,
        cartItems: [],
      }
    default:
      return state
  }
}
