import axios from 'axios'
import {
  AUTH_TOKEN_VALIDATE_REQUEST,
  AUTH_TOKEN_VALIDATE_SUCCESS,
} from '../constants/authConstants'
import { logout } from './userActions'

export const validateToken = () => async (dispatch, getState) => {
  try {
    dispatch({ type: AUTH_TOKEN_VALIDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    if (userInfo) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get('/api/auth/validate', config)

      dispatch({ type: AUTH_TOKEN_VALIDATE_SUCCESS, payload: data })
    }
  } catch (error) {
    dispatch(logout())
  }
}
