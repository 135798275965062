import React from 'react'
import {
  SurveyDropdown,
  SurveyMetric,
  SurveyRadio,
  SurveyText,
} from '../components/survey/SurveyComponents'

const Components = {
  text: SurveyText,
  radiogroup: SurveyRadio,
  metric: SurveyMetric,
  dropdown: SurveyDropdown,
}

const component = (block) => {
  let surveyPart
  if (typeof Components[block.type] !== 'undefined') {
    surveyPart = React.createElement(Components[block.type], {
      key: block._uid,
      block: block,
    })
  }

  if (surveyPart) {
    return surveyPart
  }

  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  )
}
export default component
