import {
  PRODUCT_CREATE_FAILED,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_REVIEW_FAILED,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE_FAILED,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DETAILS_FAILED,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_RESET,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_IMAGE_UPLOAD_FAILED,
  PRODUCT_IMAGE_UPLOAD_REQUEST,
  PRODUCT_IMAGE_UPLOAD_SUCCESS,
  PRODUCT_ITEMS_FAILED,
  PRODUCT_ITEMS_REQUEST,
  PRODUCT_ITEMS_RESET,
  PRODUCT_ITEMS_SUCCESS,
  PRODUCT_ITEM_CREATE_FAILED,
  PRODUCT_ITEM_CREATE_REQUEST,
  PRODUCT_ITEM_CREATE_RESET,
  PRODUCT_ITEM_CREATE_SUCCESS,
  PRODUCT_ITEM_DELETE_FAILED,
  PRODUCT_ITEM_DELETE_REQUEST,
  PRODUCT_ITEM_DELETE_RESET,
  PRODUCT_ITEM_DELETE_SUCCESS,
  PRODUCT_ITEM_UPDATE_FAILED,
  PRODUCT_ITEM_UPDATE_RESET,
  PRODUCT_ITEM_UPDATE_SUCCESS,
  PRODUCT_LIST_FAILED,
  PRODUCT_LIST_PUBLISHED_FAILED,
  PRODUCT_LIST_PUBLISHED_REQUEST,
  PRODUCT_LIST_PUBLISHED_SUCCESS,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_TOP_FAILED,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_UPDATE_FAILED,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_SUCCESS,
} from '../constants/productConstants'

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload }
    case PRODUCT_LIST_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productListPublishedReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_PUBLISHED_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_LIST_PUBLISHED_SUCCESS:
      return { loading: false, products: action.payload }
    case PRODUCT_LIST_PUBLISHED_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, ...state }
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload }
    case PRODUCT_DETAILS_FAILED:
      return { loading: false, error: action.payload }
    case PRODUCT_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true }
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_DELETE_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_UPDATE_FAILED:
      return { loading: false, error: action.payload }
    case PRODUCT_UPDATE_RESET:
      return { product: {} }
    default:
      return state
  }
}

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_CREATE_FAILED:
      return { loading: false, error: action.payload }
    case PRODUCT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const productImageUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_IMAGE_UPLOAD_REQUEST:
      return { loading: true }
    case PRODUCT_IMAGE_UPLOAD_SUCCESS:
      return { loading: false, success: true, url: action.payload }
    case PRODUCT_IMAGE_UPLOAD_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_CREATE_REVIEW_FAILED:
      return { loading: false, error: action.payload }
    case PRODUCT_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const productTopRatedListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_TOP_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_TOP_SUCCESS:
      return { loading: false, products: action.payload }
    case PRODUCT_TOP_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productItemsReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ITEMS_REQUEST:
      return { loading: true }
    case PRODUCT_ITEMS_SUCCESS:
      return { loading: false, items: action.payload }
    case PRODUCT_ITEMS_FAILED:
      return { loading: false, error: action.payload }
    case PRODUCT_ITEMS_RESET:
      return {}
    default:
      return state
  }
}

export const productItemDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ITEM_DELETE_REQUEST:
      return { loading: true }
    case PRODUCT_ITEM_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_ITEM_DELETE_FAILED:
      return { loading: false, error: action.payload }
    case PRODUCT_ITEM_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const productItemUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_ITEM_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_ITEM_UPDATE_FAILED:
      return { loading: false, error: action.payload }
    case PRODUCT_ITEM_UPDATE_RESET:
      return { product: {} }
    default:
      return state
  }
}

export const productItemCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ITEM_CREATE_REQUEST:
      return { loading: true }
    case PRODUCT_ITEM_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_ITEM_CREATE_FAILED:
      return { loading: false, error: action.payload }
    case PRODUCT_ITEM_CREATE_RESET:
      return {}
    default:
      return state
  }
}
