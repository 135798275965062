import {
  ORDER_ASSESSMENT_CREATE_FAILED,
  ORDER_ASSESSMENT_CREATE_REQUEST,
  ORDER_ASSESSMENT_CREATE_RESET,
  ORDER_ASSESSMENT_CREATE_SUCCESS,
  ORDER_ASSESSMENT_DETAILS_FAILED,
  ORDER_ASSESSMENT_DETAILS_REQUEST,
  ORDER_ASSESSMENT_DETAILS_RESET,
  ORDER_ASSESSMENT_DETAILS_SUCCESS,
  ORDER_ASSESSMENT_LIST_FAILED,
  ORDER_ASSESSMENT_LIST_REQUEST,
  ORDER_ASSESSMENT_LIST_RESET,
  ORDER_ASSESSMENT_LIST_SUCCESS,
  ORDER_ASSESSMENT_UPDATE_FAILED,
  ORDER_ASSESSMENT_UPDATE_REQUEST,
  ORDER_ASSESSMENT_UPDATE_RESET,
  ORDER_ASSESSMENT_UPDATE_SUCCESS,
  ORDER_CREATE_FAILED,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_RESET,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_FAILED,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DETAILS_FAILED,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_RESET,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAILED,
  ORDER_LIST_MY_FAILED,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_LIST_RESET,
  ORDER_LIST_SUCCESS,
  ORDER_PAY_FAILED,
  ORDER_PAY_REQUEST,
  ORDER_PAY_RESET,
  ORDER_PAY_SUCCESS,
  ORDER_UPDATE_FAILED,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_RESET,
  ORDER_UPDATE_SUCCESS,
} from '../constants/orderConstants'
import firebase from '../firebase/firebase'

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { ...state, loading: true }
    case ORDER_CREATE_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, success: true, order: action.payload }
    case ORDER_CREATE_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const orderDetailsReducer = (
  state = { loading: true, orderItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { loading: true }
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload }
    case ORDER_DETAILS_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_DETAILS_RESET:
      return { loading: true, orderItems: [], shippingAddress: {} }
    default:
      return state
  }
}

export const orderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return { loading: true }
    case ORDER_PAY_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, success: true }
    case ORDER_PAY_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_PAY_RESET:
      return { loading: false }
    default:
      return state
  }
}

export const orderListMyReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_MY_REQUEST:
      return { loading: true }
    case ORDER_LIST_MY_SUCCESS:
      return { loading: false, orders: action.payload }
    case ORDER_LIST_MY_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_LIST_MY_RESET:
      return { orders: [] }
    default:
      return state
  }
}

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true }
    case ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case ORDER_LIST_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_LIST_RESET:
      return { orders: [] }
    default:
      return state
  }
}

export const orderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return { loading: true }
    case ORDER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case ORDER_DELETE_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderUpdateReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return { ...state, loading: true }
    case ORDER_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case ORDER_UPDATE_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_UPDATE_RESET:
      return { order: {} }
    default:
      return state
  }
}

export const orderAssessmentCreateReducer = (
  state = { assessment: {} },
  action
) => {
  switch (action.type) {
    case ORDER_ASSESSMENT_CREATE_REQUEST:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { ...state, loading: true }
    case ORDER_ASSESSMENT_CREATE_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, success: true, assessment: action.payload }
    case ORDER_ASSESSMENT_CREATE_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_ASSESSMENT_CREATE_RESET:
      return { assessment: {} }
    default:
      return state
  }
}

export const orderAssessmentDetailsReducer = (
  state = { loading: true, assessment: {} },
  action
) => {
  switch (action.type) {
    case ORDER_ASSESSMENT_DETAILS_REQUEST:
      return { loading: true }
    case ORDER_ASSESSMENT_DETAILS_SUCCESS:
      return { loading: false, assessment: action.payload }
    case ORDER_ASSESSMENT_DETAILS_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_ASSESSMENT_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const orderAssessmentUpdateReducer = (
  state = { assessment: {} },
  action
) => {
  switch (action.type) {
    case ORDER_ASSESSMENT_UPDATE_REQUEST:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { ...state, loading: true }
    case ORDER_ASSESSMENT_UPDATE_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, success: true, assessment: action.payload }
    case ORDER_ASSESSMENT_UPDATE_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_ASSESSMENT_UPDATE_RESET:
      return { assessment: {} }
    default:
      return state
  }
}

export const orderAssessmentListReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ASSESSMENT_LIST_REQUEST:
      return { ...state, loading: true }
    case ORDER_ASSESSMENT_LIST_SUCCESS:
      return { loading: false, assessments: action.payload }
    case ORDER_ASSESSMENT_LIST_FAILED:
      return { loading: false, error: action.payload }
    case ORDER_ASSESSMENT_LIST_RESET:
      return {}
    default:
      return state
  }
}
