import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { validateToken } from '../actions/authActions'
import { logout } from '../actions/userActions'

const withAuthentication = (WrappedComponent) => {
  const AuthenticatedComponent = ({ props, history, match, location }) => {
    const dispatch = useDispatch()

    const authToken = useSelector((state) => state.authToken)
    const { success, error } = authToken

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
      dispatch(validateToken())
      if (error || !userInfo) {
        history.push(`/login?redirect=${location.pathname}`)
        dispatch(logout())
      }
    }, [dispatch, history, error, userInfo, location])

    const loginErrorMessage = (
      <div>
        Please <a href='/login'>login</a> in order to continue.
      </div>
    )

    return (
      <div>
        {success ? (
          <WrappedComponent {...props} history={history} match={match} />
        ) : (
          loginErrorMessage
        )}
      </div>
    )
  }

  return AuthenticatedComponent
}

export default withAuthentication
