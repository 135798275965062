import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getOrderAssessmentDetails,
  updateOrderAssessment,
} from '../actions/orderActions'
import { logout } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {
  ORDER_ASSESSMENT_CREATE_RESET,
  ORDER_ASSESSMENT_DETAILS_RESET,
  ORDER_ASSESSMENT_UPDATE_RESET,
} from '../constants/orderConstants'
import Meta from '../components/Meta'
import withAuthentication from '../firebase/withAuthentication'
import Survey from '../components/survey/Survey'

// https://surveyjs.io

const OrderAssessmentEditScreen = ({ history, match }) => {
  const orderId = match.params.orderId
  const assessmentId = match.params.assessmentId

  const dispatch = useDispatch()

  const orderAssessmentDetails = useSelector(
    (state) => state.orderAssessmentDetails
  )
  const { error, loading, assessment } = orderAssessmentDetails

  const orderAssessmentUpdate = useSelector(
    (state) => state.orderAssessmentUpdate
  )
  const { success } = orderAssessmentUpdate

  useEffect(() => {
    if (error) {
      dispatch(logout())
      history.push(
        `/login?redirect=assessment/${match.params.orderId}/${match.params.assessmentId}`
      )
      dispatch({ type: ORDER_ASSESSMENT_DETAILS_RESET })
    } else if (success) {
      history.push(`/order/${match.params.orderId}`)
      dispatch({ type: ORDER_ASSESSMENT_DETAILS_RESET })
      dispatch({ type: ORDER_ASSESSMENT_CREATE_RESET })
      dispatch({ type: ORDER_ASSESSMENT_UPDATE_RESET })
    } else {
      dispatch(
        getOrderAssessmentDetails(
          match.params.orderId,
          match.params.assessmentId
        )
      )
    }
  }, [history, dispatch, match, error, success])

  const questions = {
    pages: [
      {
        questions: [
          {
            type: 'text',
            inputType: 'number',
            name: 'age',
            title: 'Age',
            isRequired: true,
          },
          {
            type: 'radiogroup',
            name: 'sex',
            title: 'Sex',
            choices: ['M', 'F'],
            isRequired: true,
          },
          {
            type: 'metric',
            unit: 'lb',
            name: 'weight',
            title: 'Weight',
            isRequired: true,
          },
          {
            type: 'metric',
            unit: 'ft',
            name: 'height',
            title: 'Height',
            isRequired: true,
          },
          {
            type: 'dropdown',
            name: 'activity',
            title: 'How active are you?',
            choices: [
              {
                value: 1,
                text: 'Sedentary (little or no exercise, desk job)',
              },
              {
                value: 2,
                text: 'Lightly active (light exercise/ sports 1-3 days/week)',
              },
              {
                value: 3,
                text:
                  'Moderately active (moderate exercise/ sports 6-7 days/week)',
              },
              {
                value: 4,
                text:
                  'Very active (hard exercise every day, or exercising 2 xs/day)',
              },
              {
                value: 5,
                text:
                  'Extra active (hard exercise 2 or more times per day, or training for marathon, or triathlon, etc.',
              },
            ],
            isRequired: true,
          },
        ],
      },
    ],
  }

  const onComplete = (survey) => {
    dispatch(updateOrderAssessment(orderId, assessmentId, survey))
  }

  return (
    <>
      <Meta title={'Edit Survey'} />
      <Link className='btn btn-light my-3' to={`/order/${orderId}`}>
        Go Back
      </Link>

      {loading ? (
        <Loader>Loading...</Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        assessment && (
          <Survey
            onComplete={onComplete}
            questions={questions}
            data={assessment}
          />
        )
      )}
    </>
  )
}

export default withAuthentication(OrderAssessmentEditScreen)
