import React, { useState } from 'react'
import { Button, InputGroup, Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

// https://dev.to/alecgrey/controlled-forms-with-front-and-backend-validations-using-react-bootstrap-5a2

const MacroForm = ({ show, handleClose, handleSave }) => {
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      })
  }

  const findFormErrors = () => {
    const { age, sex, weight_lb, height_ft, height_in, activity } = form
    const newErrors = {}
    // age errors
    if (!age || age > 99 || age < 12)
      newErrors.age = 'must be between 13 and 99!'

    // sex errors
    if (!sex || sex === '') newErrors.sex = 'select a sex!'

    // weight errors
    if (!weight_lb || weight_lb < 0)
      newErrors.weight_lb = 'must be more than 0!'

    // height errors
    if (!height_ft || height_ft < 0) {
      newErrors.height_ft = 'must be more than 0!'
    }

    if (height_in && height_in < 0) {
      newErrors.height_in = 'must be at least than 0!'
    }

    // activity errors
    if (!activity || activity === '')
      newErrors.activity = 'select an activity level!'
    return newErrors
  }

  const handleSubmit = () => {
    // e.preventDefault()
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      const { age, sex, weight_lb, height_ft, height_in, activity } = form
      const answers = {
        age: age * 1,
        sex: sex,
        weight: { unit: 'lb', value: weight_lb * 1 },
        height: { unit: 'ft', value: { ft: height_ft * 1, in: height_in * 1 } },
        activity: activity * 1,
      }
      handleClose()
      handleSave(answers)
    }
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Measurements</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Age</Form.Label>
              <Form.Control
                type='number'
                onChange={(e) => setField('age', e.target.value)}
                isInvalid={!!errors.age}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.age}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Sex</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setField('sex', e.target.value)}
                isInvalid={!!errors.sex}
              >
                <option value=''>Select:</option>
                <option value='M'>Male</option>
                <option value='F'>Female</option>
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {errors.sex}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Weight</Form.Label>
              <InputGroup>
                <InputGroup.Prepend className='my-0'>
                  <InputGroup.Text>lb</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className='my-0'
                  type='number'
                  onChange={(e) => setField('weight_lb', e.target.value)}
                  isInvalid={!!errors.weight_lb}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.weight_lb}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Height</Form.Label>
              <InputGroup className='my-1'>
                <InputGroup.Prepend className='my-0'>
                  <InputGroup.Text>ft</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className='my-0'
                  type='number'
                  onChange={(e) => setField('height_ft', e.target.value)}
                  isInvalid={!!errors.height_ft}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.height_ft}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup className='my-1'>
                <InputGroup.Prepend className='my-0'>
                  <InputGroup.Text>in</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className='my-0'
                  type='number'
                  onChange={(e) => setField('height_in', e.target.value)}
                  isInvalid={!!errors.height_in}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.height_in}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>How active are you?</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setField('activity', e.target.value)}
                isInvalid={!!errors.activity}
              >
                <option value=''>Select:</option>
                <option value='1'>
                  Sedentary (little or no exercise, desk job)
                </option>
                <option value='2'>
                  Lightly active (light exercise/ sports 1-3 days/week)
                </option>
                <option value='3'>
                  Moderately active (moderate exercise/ sports 6-7 days/week)
                </option>
                <option value='4'>
                  Very active (hard exercise every day, or exercising 2 xs/day)
                </option>
                <option value='5'>
                  Extra active (hard exercise 2 or more times per day, or
                  training for marathon, or triathlon, etc.
                </option>
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {errors.activity}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default MacroForm
