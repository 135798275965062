import {
    CONTACT_FORM_SEND_FAILED,
    CONTACT_FORM_SEND_REQUEST,
    CONTACT_FORM_SEND_SUCCESS,
    CONTACT_FORM_SEND_RESET,
} from '../constants/contactConstants'

export const contactFormReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_FORM_SEND_REQUEST:
            return {}
        case CONTACT_FORM_SEND_SUCCESS:
            return { success: true }
        case CONTACT_FORM_SEND_FAILED:
            return { error: action.payload }
        case CONTACT_FORM_SEND_RESET:
            return {}
        default:
            return state
    }
}