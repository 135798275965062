import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function LanguageSelector() {
  const languages = [
    { key: 'en', name: 'English' },
    { key: 'fr', name: 'Français' },
    { key: 'es', name: 'Español' },
    { key: 'ht', name: 'Kreyòl ayisyen' },
  ]
  const { i18n } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  return (
    <NavDropdown title={<i className='fas fa-globe'></i>} id='LanguageSelector'>
      {languages.map((l) => (
        <NavDropdown.Item key={l.key} onClick={() => changeLanguage(l.key)}>
          {l.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  )
}
