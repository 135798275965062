import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { listPublichedProducts } from '../actions/productActions'
import Banner from '../components/Banner/Banner'
import Meta from '../components/Meta'
import { PRODUCT_DETAILS_RESET } from '../constants/productConstants'
import SocialFollow from '../components/SocialFollow/SocialFollow'
import { useLocation } from 'react-router-dom'
import MacroCalculator from '../components/MacroCalculator/MacroCalculator'

const HomeScreen = ({ history }) => {
  const dispatch = useDispatch()
  const { hash } = useLocation()

  useEffect(() => {
    // if not a hash link scroll to top
    if (hash === '') {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }) // do this on route change

  useEffect(() => {
    dispatch(listPublichedProducts())
    dispatch({ type: PRODUCT_DETAILS_RESET })
  }, [dispatch])

  return (
    <>
      <Meta />
      <Banner />
      <MacroCalculator />
      <SocialFollow />
    </>
  )
}

export default HomeScreen
