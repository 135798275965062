import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import './bootstrap.min.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import store from './store'
import Moment from 'react-moment'
import './i18n'

// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'en'

// Set the output format for every react-moment instance.
Moment.globalFormat = 'D MMM YYYY HH:mm:ss'

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div>Loading ……</div>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
