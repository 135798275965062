import axios from 'axios'
import { CART_CLEAR } from '../constants/cartConstants'
import {
  ORDER_ASSESSMENT_CREATE_FAILED,
  ORDER_ASSESSMENT_CREATE_REQUEST,
  ORDER_ASSESSMENT_CREATE_SUCCESS,
  ORDER_ASSESSMENT_DETAILS_FAILED,
  ORDER_ASSESSMENT_DETAILS_REQUEST,
  ORDER_ASSESSMENT_DETAILS_SUCCESS,
  ORDER_ASSESSMENT_LIST_FAILED,
  ORDER_ASSESSMENT_LIST_REQUEST,
  ORDER_ASSESSMENT_LIST_RESET,
  ORDER_ASSESSMENT_LIST_SUCCESS,
  ORDER_ASSESSMENT_UPDATE_FAILED,
  ORDER_ASSESSMENT_UPDATE_REQUEST,
  ORDER_ASSESSMENT_UPDATE_SUCCESS,
  ORDER_CREATE_FAILED,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_RESET,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_FAILED,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DETAILS_FAILED,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAILED,
  ORDER_LIST_MY_FAILED,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_LIST_RESET,
  ORDER_LIST_SUCCESS,
  ORDER_PAY_FAILED,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_UPDATE_FAILED,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
} from '../constants/orderConstants'

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/orders`, order, config)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    })

    await dispatch(createOrderAssessment(data._id))
    dispatch({ type: ORDER_CREATE_RESET })
    dispatch({ type: CART_CLEAR })
    dispatch({ type: ORDER_LIST_MY_RESET })

    localStorage.removeItem('cartItems')
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders/${id}`, config)

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const payOrder = (orderId, paymentResult) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: ORDER_PAY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/orders/${orderId}/pay`, paymentResult, config)

    dispatch({ type: ORDER_PAY_SUCCESS })
    dispatch({ type: ORDER_LIST_RESET })
  } catch (error) {
    dispatch({
      type: ORDER_PAY_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/orders/myorders', config)

    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_LIST_MY_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/orders`, config)

    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/orders/${id}`, config)

    dispatch({ type: ORDER_DELETE_SUCCESS })
    dispatch({ type: ORDER_LIST_RESET })
  } catch (error) {
    dispatch({
      type: ORDER_DELETE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateOrder = (id, order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/orders/${id}`, order, config)

    dispatch({
      type: ORDER_UPDATE_SUCCESS,
    })
    dispatch({
      type: ORDER_LIST_RESET,
    })
  } catch (error) {
    dispatch({
      type: ORDER_UPDATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createOrderAssessment = (orderId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: ORDER_ASSESSMENT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/orders/${orderId}/assessments`,
      {},
      config
    )

    dispatch({
      type: ORDER_ASSESSMENT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_ASSESSMENT_CREATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderAssessmentDetails = (orderId, assessmentId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: ORDER_ASSESSMENT_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/orders/${orderId}/assessments/${assessmentId}`,
      config
    )

    dispatch({
      type: ORDER_ASSESSMENT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_ASSESSMENT_DETAILS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateOrderAssessment = (
  orderId,
  assessmentId,
  assessment
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_ASSESSMENT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/nutrition/macro`,
      assessment,
      config
    )

    await axios.put(
      `/api/orders/${orderId}/assessments/${assessmentId}`,
      { ...assessment, macros: data },
      config
    )

    dispatch({
      type: ORDER_ASSESSMENT_UPDATE_SUCCESS,
    })
    dispatch({ type: ORDER_ASSESSMENT_LIST_RESET })
  } catch (error) {
    dispatch({
      type: ORDER_ASSESSMENT_UPDATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listOrderAssessments = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_ASSESSMENT_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/orders/${orderId}/assessments`,
      config
    )

    dispatch({
      type: ORDER_ASSESSMENT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_ASSESSMENT_LIST_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
