import {
  TAG_CREATE_FAILED,
  TAG_CREATE_REQUEST,
  TAG_CREATE_RESET,
  TAG_CREATE_SUCCESS,
  TAG_DELETE_FAILED,
  TAG_DELETE_REQUEST,
  TAG_DELETE_RESET,
  TAG_DELETE_SUCCESS,
  TAG_LIST_FAILED,
  TAG_LIST_REQUEST,
  TAG_LIST_RESET,
  TAG_LIST_SUCCESS,
  TAG_UPDATE_FAILED,
  TAG_UPDATE_REQUEST,
  TAG_UPDATE_RESET,
  TAG_UPDATE_SUCCESS,
} from '../constants/tagConstants'

export const tagListReducer = (state = {}, action) => {
  switch (action.type) {
    case TAG_LIST_REQUEST:
      return { loading: true }
    case TAG_LIST_SUCCESS:
      return { loading: false, tags: action.payload }
    case TAG_LIST_FAILED:
      return { loading: false, error: action.payload }
    case TAG_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const tagDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TAG_DELETE_REQUEST:
      return { loading: true }
    case TAG_DELETE_SUCCESS:
      return { loading: false, success: true }
    case TAG_DELETE_FAILED:
      return { loading: false, error: action.payload }
    case TAG_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const tagUpdateReducer = (state = { tag: {} }, action) => {
  switch (action.type) {
    case TAG_UPDATE_REQUEST:
      return { loading: true }
    case TAG_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case TAG_UPDATE_FAILED:
      return { loading: false, error: action.payload }
    case TAG_UPDATE_RESET:
      return { tag: {} }
    default:
      return state
  }
}

export const tagCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TAG_CREATE_REQUEST:
      return { loading: true }
    case TAG_CREATE_SUCCESS:
      return { loading: false, success: true, tag: action.payload }
    case TAG_CREATE_FAILED:
      return { loading: false, error: action.payload }
    case TAG_CREATE_RESET:
      return {}
    default:
      return state
  }
}
