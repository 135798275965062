import axios from 'axios'
import {
  TAG_CREATE_FAILED,
  TAG_CREATE_REQUEST,
  TAG_CREATE_RESET,
  TAG_CREATE_SUCCESS,
  TAG_DELETE_FAILED,
  TAG_DELETE_REQUEST,
  TAG_DELETE_RESET,
  TAG_DELETE_SUCCESS,
  TAG_LIST_FAILED,
  TAG_LIST_REQUEST,
  TAG_LIST_RESET,
  TAG_LIST_SUCCESS,
  TAG_UPDATE_FAILED,
  TAG_UPDATE_REQUEST,
  TAG_UPDATE_SUCCESS,
} from '../constants/tagConstants'

export const listTags = (keyword = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: TAG_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/tags?keyword=${keyword}`, config)

    dispatch({ type: TAG_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TAG_LIST_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteTag = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TAG_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/tags/${id}`, config)

    dispatch({ type: TAG_DELETE_SUCCESS })
    dispatch({ type: TAG_LIST_RESET })
    dispatch({ type: TAG_CREATE_RESET })
  } catch (error) {
    dispatch({
      type: TAG_DELETE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createTag = (tag) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TAG_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/tags`, tag, config)

    dispatch({
      type: TAG_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: TAG_LIST_RESET })
    dispatch({ type: TAG_DELETE_RESET })
  } catch (error) {
    dispatch({
      type: TAG_CREATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateTag = (id, tag) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TAG_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/tags/${id}`, tag, config)
    dispatch({
      type: TAG_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TAG_UPDATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
