import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listProductDetails } from '../actions/productActions'
import Meta from '../components/Meta'
import { addPlanToCart } from '../actions/cartActions'
import Packages from '../components/Packages'
import { Container } from 'react-bootstrap'

const ProductScreen = ({ history, match }) => {
  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  useEffect(() => {
    dispatch(listProductDetails(match.params.id))
  }, [dispatch, match])

  const addToCartHandler = (p) => {
    dispatch(addPlanToCart(p))
    history.push('/payment')
  }

  return (
    <>
      <Container>
        <Link className='btn btn-light my-3' to='/'>
          Go Back
        </Link>
      </Container>

      {loading && <Loader>Loading...</Loader>}
      {error && <Message variant='danger'>{error}</Message>}
      {product && (
        <>
          <Meta title={product.name} />
          <Container>
            <h1>{product.name}</h1>
            <p>Description</p>
            <h2>What's Included</h2>
          </Container>
          <Packages product={product} addToCartHandler={addToCartHandler} />
        </>
      )}
    </>
  )
}

export default ProductScreen
