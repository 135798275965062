import React, { useEffect } from 'react'
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import {
  createOrderAssessment,
  getOrderDetails,
  listOrderAssessments,
  payOrder,
} from '../actions/orderActions'
import { logout } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import PaypalButton from '../components/PaypalButton'
import {
  ORDER_ASSESSMENT_CREATE_RESET,
  ORDER_DETAILS_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants'
import Meta from '../components/Meta'
import Moment from 'react-moment'

const OrderScreen = ({ history, match }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderAssessmentCreate = useSelector(
    (state) => state.orderAssessmentCreate
  )
  const { assessment, success: successAssessment } = orderAssessmentCreate

  const orderAssessmentList = useSelector((state) => state.orderAssessmentList)
  const { assessments } = orderAssessmentList

  useEffect(() => {
    if (!assessments) {
      dispatch(listOrderAssessments(orderId))
    }
  }, [dispatch, assessments, orderId])

  useEffect(() => {
    if (successAssessment) {
      history.push(`/assessment/${orderId}/${assessment._id}`)
      dispatch({ type: ORDER_ASSESSMENT_CREATE_RESET })
    } else if (!order || successPay) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch(getOrderDetails(orderId))
    } else if (error) {
      dispatch(logout())
      history.push(`/login?redirect=/order/${orderId}`)
      dispatch({ type: ORDER_DETAILS_RESET })
    }
  }, [
    dispatch,
    history,
    order,
    orderId,
    successPay,
    error,
    successAssessment,
    assessment,
  ])

  const successPaymentHandler = (paymentResult) => {
    const paidBy = {
      id: paymentResult.id,
      status: paymentResult.status,
      update_time: paymentResult.update_time,
      email_address: paymentResult.payer.email_address,
      name: paymentResult.payer.name.given_name,
    }
    dispatch(payOrder(orderId, paidBy))
  }

  const manualPaymentHandler = () => {
    const paidBy = {
      status: 'MANUAL COMPLETE',
      email_address: userInfo.email,
    }
    dispatch(payOrder(orderId, paidBy))
  }

  const createAssessmentHandler = () => {
    dispatch(createOrderAssessment(orderId))
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <Meta title={'Order Details'} />
      {userInfo && userInfo.isAdmin && (
        <Link to='/admin/orderlist' className='btn btn-light my-3'>
          Go Back
        </Link>
      )}
      <h1>Order {orderId}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Email: </strong>{' '}
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {order.paymentMethod.name}
              </p>
              {order.isPaid ? (
                <Message variant='success'>Paid</Message>
              ) : (
                <Message variant='danger'>Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row sm={2} md={3} lg={4}>
                        <Col>{item.category}</Col>
                        <Col>{item.name}</Col>
                        <Col>${item.price}</Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <ListGroup>
                <Row className='align-items-center'>
                  <Col>
                    <h2>Assessments</h2>
                  </Col>
                  <Col className='text-right'>
                    <Button className='my-3' onClick={createAssessmentHandler}>
                      <i className='fas fa-plus'>Add Assessment</i>
                    </Button>
                  </Col>
                </Row>
              </ListGroup>
              {!assessments || assessments.length === 0 ? (
                <Message>No Assessments.</Message>
              ) : (
                <Row sm={2} md={3} lg={3}>
                  {assessments.map((item, index) => (
                    <Col key={index}>
                      <LinkContainer to={`/assessment/${orderId}/${item._id}`}>
                        <Card border='light'>
                          <Card.Header>Calories</Card.Header>
                          <Card.Body>
                            <Card.Title>
                              {item.macros && item.macros.calories}
                            </Card.Title>
                          </Card.Body>
                          <Card.Footer>
                            <small className='text-muted'>
                              <Moment>
                                {new Date(item.createdAt._seconds * 1000)}
                              </Moment>
                            </small>
                          </Card.Footer>
                        </Card>
                      </LinkContainer>
                    </Col>
                  ))}
                </Row>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup>
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>${order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}
                  {order.paymentMethod.name === 'PayPal' && (
                    <PaypalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                  {userInfo &&
                    userInfo.isAdmin &&
                    !order.isPaid &&
                    order.paymentMethod.name === 'Cash' && (
                      <Button
                        type='button'
                        className='btn btn-block my-3'
                        onClick={manualPaymentHandler}
                      >
                        Mark As Paid
                      </Button>
                    )}
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default OrderScreen
