import React, { useEffect } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { deleteBlog, listBlogs } from '../actions/blogActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'
import Category from '../components/Category'
import { validateToken } from '../actions/authActions'

const BlogListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const blogList = useSelector((state) => state.blogList)
  const { loading, error, blogs } = blogList

  const blogCreate = useSelector((state) => state.blogCreate)
  const { error: errorBlogCreate, success: successBlogCreate } = blogCreate

  const deleteHandler = (id) => {
    if (window.confirm(`Are you sure?`)) {
      dispatch(deleteBlog(id))
    }
  }
  const showFormToggle = () => {
    history.push('/admin/blog/new')
  }

  useEffect(() => {
    dispatch(validateToken())
    if (!userInfo) {
      history.push('/login?redirect=admin/blogList')
    } else if (userInfo && !userInfo.isAdmin) {
      history.push('/')
    }
  }, [dispatch, userInfo, history])

  useEffect(() => {
    if (!blogs) {
      dispatch(listBlogs())
    }
  }, [dispatch, blogs])

  return (
    <>
      <Meta title={'Blogs List'} />
      <Category />
      {errorBlogCreate && <Message variant='danger'>{errorBlogCreate}</Message>}
      {successBlogCreate && <Message variant='success'>Blog created.</Message>}

      <Row className='align-items-center'>
        <Col>
          <h1>Blogs</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={showFormToggle}>
            <i className='fas fa-plus'> Add Blog</i>
          </Button>
        </Col>
      </Row>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ID</th>
              <th>TITLE</th>
              <th>PUBLISHED</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {blogs &&
              blogs.map((blog) => (
                <tr key={blog._id}>
                  <td>{blog._id}</td>
                  <td>{blog.title}</td>
                  <td>
                    {blog.isPublished ? (
                      <i
                        className='fas fa-check'
                        style={{ color: 'green' }}
                      ></i>
                    ) : (
                      <i className='fas fa-times' style={{ color: 'red' }}></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/blog/${blog._id}/edit`}>
                      <Button className='btn-sm' variant='light'>
                        <i className='fas fa-edit' />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='light'
                      className='btn-sm'
                      onClick={() => deleteHandler(blog._id)}
                    >
                      <i className='fas fa-trash' style={{ color: 'red' }} />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  )
}
export default BlogListScreen
