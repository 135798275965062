export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST'
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS'
export const BLOG_LIST_FAILED = 'BLOG_LIST_FAILED'
export const BLOG_LIST_RESET = 'BLOG_LIST_RESET'

export const BLOG_LIST_PUBLISHED_REQUEST = 'BLOG_LIST_PUBLISHED_REQUEST'
export const BLOG_LIST_PUBLISHED_SUCCESS = 'BLOG_LIST_PUBLISHED_SUCCESS'
export const BLOG_LIST_PUBLISHED_FAILED = 'BLOG_LIST_PUBLISHED_FAILED'
export const BLOG_LIST_PUBLISHED_RESET = 'BLOG_LIST_PUBLISHED_RESET'

export const BLOG_DETAILS_REQUEST = 'BLOG_DETAILS_REQUEST'
export const BLOG_DETAILS_SUCCESS = 'BLOG_DETAILS_SUCCESS'
export const BLOG_DETAILS_FAILED = 'BLOG_DETAILS_FAILED'
export const BLOG_DETAILS_RESET = 'BLOG_DETAILS_RESET'

export const BLOG_DELETE_REQUEST = 'BLOG_DELETE_REQUEST'
export const BLOG_DELETE_SUCCESS = 'BLOG_DELETE_SUCCESS'
export const BLOG_DELETE_FAILED = 'BLOG_DELETE_FAILED'

export const BLOG_UPDATE_REQUEST = 'BLOG_UPDATE_REQUEST'
export const BLOG_UPDATE_SUCCESS = 'BLOG_UPDATE_SUCCESS'
export const BLOG_UPDATE_FAILED = 'BLOG_UPDATE_FAILED'
export const BLOG_UPDATE_RESET = 'BLOG_UPDATE_RESET'

export const BLOG_CREATE_REQUEST = 'BLOG_CREATE_REQUEST'
export const BLOG_CREATE_SUCCESS = 'BLOG_CREATE_SUCCESS'
export const BLOG_CREATE_FAILED = 'BLOG_CREATE_FAILED'
export const BLOG_CREATE_RESET = 'BLOG_CREATE_RESET'

export const BLOG_IMAGE_UPLOAD_REQUEST = 'BLOG_IMAGE_UPLOAD_REQUEST'
export const BLOG_IMAGE_UPLOAD_SUCCESS = 'BLOG_IMAGE_UPLOAD_SUCCESS'
export const BLOG_IMAGE_UPLOAD_FAILED = 'BLOG_IMAGE_UPLOAD_FAILED'

export const BLOG_CREATE_COMMENT_REQUEST = 'BLOG_CREATE_COMMENT_REQUEST'
export const BLOG_CREATE_COMMENT_SUCCESS = 'BLOG_CREATE_COMMENT_SUCCESS'
export const BLOG_CREATE_COMMENT_FAILED = 'BLOG_CREATE_COMMENT_FAILED'
export const BLOG_CREATE_COMMENT_RESET = 'BLOG_CREATE_COMMENT_RESET'

export const BLOG_TOP_REQUEST = 'BLOG_TOP_REQUEST'
export const BLOG_TOP_SUCCESS = 'BLOG_TOP_SUCCESS'
export const BLOG_TOP_FAILED = 'BLOG_TOP_FAILED'
