import React from 'react'
import { Nav } from 'react-bootstrap'

const Breadcrumb = ({ onSelect, crumbs }) => {
  const options =
    crumbs &&
    crumbs.map((p) => (
      <Nav.Item key={p}>
        <Nav.Link eventKey={p}>{p}</Nav.Link>
      </Nav.Item>
    ))

  return (
    <div>
      {crumbs && (
        <Nav
          variant='tabs'
          defaultActiveKey='maintenace'
          onSelect={(eventKey) => onSelect(eventKey)}
        >
          {options}
        </Nav>
      )}
    </div>
  )
}

export default Breadcrumb
