import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Components from '../../utils/surveyUtils'
import deepmerge from 'deepmerge'

const Survey = ({ onComplete, data, questions: survey, title }) => {
  const [answers, setAnswers] = useState(data || {})

  const submitHandler = (e) => {
    e.preventDefault()
    onComplete && onComplete(answers)
  }

  const changeHandler = (k, v) => {
    let newValue = v
    const current = answers[k]
    if (current && typeof v === 'object') {
      newValue = deepmerge(current, v)
    }
    setAnswers({ ...answers, [k]: newValue })
  }

  return (
    <>
      <h2>{title}</h2>
      <p>{JSON.stringify(answers)}</p>
      <Form onSubmit={submitHandler}>
        {survey.pages.map((page, pid) =>
          page.questions.map((block, cid) =>
            Components({
              ...block,
              _uid: `${pid}-${cid}`,
              onChange: changeHandler,
              data: answers,
            })
          )
        )}
        <Button className='my-3' variant='outline-primary' type='submit'>
          <i className='fas fa-edit'>Submit</i>
        </Button>
      </Form>
    </>
  )
}

export default Survey
