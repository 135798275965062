import React from 'react'
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../actions/userActions'
import LanguageSelector from './LanguageSelector'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <Navbar bg='white' expand='lg' collapseOnSelect fixed='top'>
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand className='logo'>
            <img
              alt=''
              src='/favicon-32x32.png'
              width='30'
              height='30'
              className='d-inline-block align-top'
            />{' '}
            InShape
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />

        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            {/* <LinkContainer to='/blogs'>
              <Nav.Link>
                <i className='fas fa-blog'> </i> {t('Blog')}
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/#services'>
              <Nav.Link>
                <i className='fas fa-dumbbell' /> Services
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/#contacts'>
              <Nav.Link>
                <i className='fas fa-id-card' /> Contact
              </Nav.Link>
            </LinkContainer> */}
            {userInfo ? (
              <NavDropdown
                title={
                  <>
                    {userInfo.photoURL ? (
                      <Image
                        roundedCircle
                        fluid
                        src={userInfo.photoURL}
                        alt={userInfo.name}
                        width='30'
                        height='30'
                      />
                    ) : (
                      userInfo.name
                    )}
                  </>
                }
                id='username'
              >
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler}>
                  {t('Logout')}
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <LinkContainer to='/login'>
                <Nav.Link>
                  <i className='fas fa-user'></i> {t('Sign In')}
                </Nav.Link>
              </LinkContainer>
            )}
            {userInfo && userInfo.isAdmin && (
              <NavDropdown title='Admin' id='adminmenu'>
                <LinkContainer to='/admin/userList'>
                  <NavDropdown.Item>{t('Users')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/productList'>
                  <NavDropdown.Item>{t('Products')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/orderList'>
                  <NavDropdown.Item>{t('Orders')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/blogList'>
                  <NavDropdown.Item>{t('Blogs')}</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
            <LanguageSelector />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
