import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Image, ListGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { addToCart } from '../actions/cartActions'
import Message from '../components/Message'
import Meta from '../components/Meta'
import { useTranslation } from 'react-i18next'

const CartScreen = ({ history }) => {
  const [error, setError] = useState()
  const [minWorkout, setMinWorkout] = useState(0)
  const [maxWorkout, setMaxWorkout] = useState(0)
  const [minNutrition, setMinNutrition] = useState(0)
  const [maxNutrition, setMaxNutrition] = useState(0)

  const dispatch = useDispatch()
  const { t } = useTranslation(['translation', 'phrases'])

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  useEffect(() => {
    const minWorkout = cartItems.reduce(
      (acc, item) => acc + ((item.workouts && item.workouts.min) || 0),
      0
    )
    setMinWorkout(minWorkout)

    const maxWorkout = cartItems.reduce(
      (acc, item) => acc + ((item.workouts && item.workouts.max) || 0),
      0
    )
    setMaxWorkout(maxWorkout)

    const minNutrition = cartItems.reduce(
      (acc, item) => acc + ((item.nutritions && item.nutritions.min) || 0),
      0
    )
    setMinNutrition(minNutrition)

    const maxNutrition = cartItems.reduce(
      (acc, item) => acc + ((item.nutritions && item.nutritions.max) || 0),
      0
    )
    setMaxNutrition(maxNutrition)

    const workoutItems = cartItems
      .filter((x) => x.category === 'workout')
      .reduce((acc, item) => acc + item.qty, 0)
    const nutritionItems = cartItems
      .filter((x) => x.category === 'nutrition')
      .reduce((acc, item) => acc + item.qty, 0)
    if (workoutItems < minWorkout) {
      setError({
        variant: 'danger',
        message: `Too few workouts. Min: ${minWorkout}`,
      })
    } else if (workoutItems > maxWorkout) {
      setError({
        variant: 'danger',
        message: `Too many workouts. Max: ${maxWorkout}`,
      })
    } else if (nutritionItems < minNutrition) {
      setError({
        variant: 'danger',
        message: `Too few nutritions. Min: ${minNutrition}`,
      })
    } else if (nutritionItems > maxNutrition) {
      setError({
        variant: 'danger',
        message: `Too many nutritions. Max: ${maxNutrition}`,
      })
    } else {
      setError(null)
    }
  }, [dispatch, cartItems])

  const addToCartHandler = (id, qty) => {
    dispatch(addToCart(id, qty))
  }

  const checkoutHandler = () => {
    history.push('/login?redirect=shipping')
  }

  return (
    <>
      <Meta title={'Cart'} />
      <Row>
        <Col md={8}>
          <h1>{t('Cart')}</h1>
          {cartItems.length === 0 ? (
            <Message>
              Your cart is empty <Link to='/'>Go Back</Link>
            </Message>
          ) : (
            <>
              {minWorkout !== maxWorkout && (
                <Message variant='info'>
                  Total Workout Sessions must be between {minWorkout} and{' '}
                  {maxWorkout}.
                </Message>
              )}
              {minWorkout === maxWorkout && (
                <Message variant='info'>
                  Total Workout Sessions must be {minWorkout}.
                </Message>
              )}
              {minNutrition !== maxNutrition && (
                <Message variant='info'>
                  Total Nutrition Plans must be between {minNutrition} and{' '}
                  {maxNutrition}.
                </Message>
              )}
              {minNutrition === maxNutrition && (
                <Message variant='info'>
                  Total Nutrition Plans must be {minNutrition}.
                </Message>
              )}
              <ListGroup variant='flush'>
                {cartItems.map((item) => (
                  <ListGroup.Item key={item.product}>
                    <Row sm={2} md={3} lg={4}>
                      <Col>
                        <Image
                          src={item.image}
                          alt={item.name}
                          fluid
                          rounded
                        ></Image>
                      </Col>
                      <Col>{item.name}</Col>
                      <Col>
                        {item.price === 0 ? 'Included' : `$${item.price}`}
                      </Col>
                      <Col>
                        {item.minQty === item.maxQty ? (
                          <Form.Control
                            type='text'
                            placeholder={item.maxQty}
                            readOnly
                          />
                        ) : (
                          <Form.Control
                            disabled={item.minQty === item.maxQty}
                            size='sm'
                            as='select'
                            value={item.qty}
                            onChange={(e) =>
                              addToCartHandler(item, Number(e.target.value))
                            }
                          >
                            {[...Array(item.maxQty + 1).keys()].map((x) => (
                              <option key={x} value={x}>
                                {x}
                              </option>
                            ))}
                          </Form.Control>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          )}
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>
                  Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}
                  ) items
                </h2>
                $
                {cartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(2)}
              </ListGroup.Item>
              <ListGroup.Item>
                {error ? (
                  <Message variant={error.variant}>{error.message}</Message>
                ) : (
                  <Button
                    type='button'
                    className='btn-block'
                    disabled={cartItems.length === 0}
                    onClick={checkoutHandler}
                  >
                    {t('Proceed')}
                  </Button>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default CartScreen
