import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  FacebookLoginButton,
  GoogleLoginButton,
  // InstagramLoginButton,
  TwitterLoginButton,
} from 'react-social-login-buttons'
import {
  logout,
  registerWithEmailAndPassword,
  loginWithFacebook,
  loginWithGoogle,
  loginWithTwitter,
} from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'
import { useTranslation, Trans } from 'react-i18next'

const RegisterScreen = ({ history, location }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()
  const { t } = useTranslation(['translation', 'phrases'])

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push('/login')
    }
  }, [history, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Password do not match.')
    } else {
      dispatch(registerWithEmailAndPassword(name, email, password))
      dispatch(logout())
    }
  }

  return (
    <>
      <Meta title={'Registration'} />
      <FormContainer>
        <Row className='py-3'>
          <Col>
            <GoogleLoginButton onClick={() => dispatch(loginWithGoogle())}>
              <span>Google</span>
            </GoogleLoginButton>
            {/* <InstagramLoginButton onClick={() => alert('Coming Soon')}>
              <span>Instagram</span>
            </InstagramLoginButton> */}
            <FacebookLoginButton onClick={() => dispatch(loginWithFacebook())}>
              <span>Facebook</span>
            </FacebookLoginButton>
            <TwitterLoginButton onClick={() => dispatch(loginWithTwitter())}>
              <span>Twitter</span>
            </TwitterLoginButton>
          </Col>
        </Row>

        <Row>
          <Col>
            <hr />
          </Col>
          <Col className='col-auto'>OR</Col>
          <Col>
            <hr />
          </Col>
        </Row>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader></Loader>}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Control
              type='name'
              placeholder={t('Enter Name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='email'>
            <Form.Control
              type='email'
              placeholder={t('Enter Email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Control
              type='password'
              placeholder={t('Enter Password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='confirmPassword'>
            <Form.Control
              type='password'
              placeholder={t('Confirm Password')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary'>
            {t('Register')}
          </Button>
        </Form>

        <Row className='py-3'>
          <Col>
            <Trans i18nKey='phrases:loginMessage'>
              Have an Account?{' '}
              <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                Login
              </Link>
            </Trans>
          </Col>
        </Row>
      </FormContainer>
    </>
  )
}

export default RegisterScreen
