import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, keyword }) => {
  return (
    <Helmet>
      <title>{title ? `InShape | ${title}` : 'Welcome To InShape'}</title>
      <meta name='keyword' content={keyword} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Welcome To InShape',
  keyword: 'Workout, Health, Fitness',
}

export default Meta
