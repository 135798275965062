export const TAG_LIST_REQUEST = 'TAG_LIST_REQUEST'
export const TAG_LIST_SUCCESS = 'TAG_LIST_SUCCESS'
export const TAG_LIST_FAILED = 'TAG_LIST_FAILED'
export const TAG_LIST_RESET = 'TAG_LIST_RESET'

export const TAG_DELETE_REQUEST = 'TAG_DELETE_REQUEST'
export const TAG_DELETE_SUCCESS = 'TAG_DELETE_SUCCESS'
export const TAG_DELETE_FAILED = 'TAG_DELETE_FAILED'
export const TAG_DELETE_RESET = 'TAG_DELETE_RESET'

export const TAG_UPDATE_REQUEST = 'TAG_UPDATE_REQUEST'
export const TAG_UPDATE_SUCCESS = 'TAG_UPDATE_SUCCESS'
export const TAG_UPDATE_FAILED = 'TAG_UPDATE_FAILED'
export const TAG_UPDATE_RESET = 'TAG_UPDATE_RESET'

export const TAG_CREATE_REQUEST = 'TAG_CREATE_REQUEST'
export const TAG_CREATE_SUCCESS = 'TAG_CREATE_SUCCESS'
export const TAG_CREATE_FAILED = 'TAG_CREATE_FAILED'
export const TAG_CREATE_RESET = 'TAG_CREATE_RESET'
