import axios from 'axios'
import {
  BLOG_CREATE_COMMENT_FAILED,
  BLOG_CREATE_COMMENT_REQUEST,
  BLOG_CREATE_COMMENT_SUCCESS,
  BLOG_CREATE_FAILED,
  BLOG_CREATE_REQUEST,
  BLOG_CREATE_SUCCESS,
  BLOG_DELETE_FAILED,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_SUCCESS,
  BLOG_DETAILS_FAILED,
  BLOG_DETAILS_REQUEST,
  BLOG_DETAILS_SUCCESS,
  BLOG_LIST_FAILED,
  BLOG_LIST_PUBLISHED_FAILED,
  BLOG_LIST_PUBLISHED_REQUEST,
  BLOG_LIST_PUBLISHED_SUCCESS,
  BLOG_LIST_REQUEST,
  BLOG_LIST_RESET,
  BLOG_LIST_SUCCESS,
  BLOG_TOP_FAILED,
  BLOG_TOP_REQUEST,
  BLOG_TOP_SUCCESS,
  BLOG_UPDATE_FAILED,
  BLOG_UPDATE_REQUEST,
  BLOG_UPDATE_RESET,
  BLOG_UPDATE_SUCCESS,
} from '../constants/blogConstants'

export const listBlogs = (keyword = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/blogs?keyword=${keyword}`, config)

    dispatch({ type: BLOG_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BLOG_LIST_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listPublishedBlogs = (keyword = '') => async (dispatch) => {
  try {
    dispatch({ type: BLOG_LIST_PUBLISHED_REQUEST })

    const { data } = await axios.get(`/api/blogs/published?keyword=${keyword}`)

    dispatch({ type: BLOG_LIST_PUBLISHED_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BLOG_LIST_PUBLISHED_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listBlogDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/blogs/${id}`)

    dispatch({ type: BLOG_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BLOG_DETAILS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteBlog = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BLOG_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/blogs/${id}`, config)

    dispatch({ type: BLOG_DELETE_SUCCESS })
    dispatch({ type: BLOG_LIST_RESET })
  } catch (error) {
    dispatch({
      type: BLOG_DELETE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createBlog = (blog) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BLOG_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/blogs`, blog, config)

    dispatch({
      type: BLOG_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: BLOG_LIST_RESET })
    localStorage.removeItem('blog')
  } catch (error) {
    dispatch({
      type: BLOG_CREATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateBlog = (id, blog) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BLOG_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/blogs/${id}`, blog, config)
    dispatch({
      type: BLOG_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: BLOG_LIST_RESET })
    dispatch({ type: BLOG_UPDATE_RESET })
  } catch (error) {
    dispatch({
      type: BLOG_UPDATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createBlogReview = (blogId, review) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: BLOG_CREATE_COMMENT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/blogs/${blogId}/reviews`, review, config)

    dispatch({
      type: BLOG_CREATE_COMMENT_SUCCESS,
    })
    // dispatch({ type: BLOG_LIST_RESET })
  } catch (error) {
    dispatch({
      type: BLOG_CREATE_COMMENT_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listTopBlogs = (keyword = '') => async (dispatch) => {
  try {
    dispatch({ type: BLOG_TOP_REQUEST })

    const { data } = await axios.get('/api/blogs/top')

    dispatch({ type: BLOG_TOP_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BLOG_TOP_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
