import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'

const Footer = () => {
  return (
    <footer className='footer'>
      <Container>
        <Row>
          <Col lg={4} md={6} sm={6}>
            <div className='d-flex'>
              <p>Port-au-Prince Haiti</p>
            </div>
            <div className='d-flex'>
              <a href='tel:+50944890574'>+509 44 89 0574</a>
            </div>
            <div className='d-flex'>
              <p>jnluc@hotmail.com</p>
            </div>
          </Col>
          <Col lg={3} md={2} sm={6}>
            <Row>
              <Col>
                <a href='/' className='footer-nav'>
                  Home
                </a>
                <br />
                <a href='/' className='footer-nav'>
                  About me
                </a>
              </Col>
              <Col>
                <a href='/' className='footer-nav'>
                  Services
                </a>
                <br />
                <a href='/contact' className='footer-nav'>
                  Contact
                </a>
              </Col>
            </Row>
          </Col>
          <Col lg={5} md={5} sm={6} className='align-items-center'>
            <div className='d-flex justify-content-center'>
              <FacebookShareButton
                url={'https://inshape-dev.uc.r.appspot.com/'}
                quote={'InShape'}
                hashtag='#fitness'
              >
                <FacebookIcon className='mx-3' size={36} />
              </FacebookShareButton>
              <TwitterShareButton
                url={'https://inshape-dev.uc.r.appspot.com/'}
                quote={'InShape'}
                hashtag='#fitness'
              >
                <TwitterIcon className='mx-3' size={36} />
              </TwitterShareButton>
              <WhatsappShareButton
                url={'https://inshape-dev.uc.r.appspot.com/'}
                quote={'InShape'}
                hashtag='#fitness'
              >
                <WhatsappIcon className='mx-3' size={36} />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={'https://inshape-dev.uc.r.appspot.com/'}
                quote={'InShape'}
                hashtag='#fitness'
              >
                <LinkedinIcon className='mx-3' size={36} />
              </LinkedinShareButton>
              <EmailShareButton
                subject={'InShape'}
                body={'https://inshape-dev.uc.r.appspot.com'}
              >
                <EmailIcon className='mx-3' size={36} />
              </EmailShareButton>
            </div>
            <p className='pt-3 text-center'>
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;InShape | All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
