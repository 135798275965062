import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { Col, Container, Row } from 'react-bootstrap'
import './SocialFollow.css'

// https://www.digitalocean.com/community/tutorials/creating-a-social-follow-component-in-react

export default function SocialFollow() {
  return (
    <div className='social-container'>
      <Container>
        <Row>
          <Col>
            <h1>Follow Me</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <a
              href='https://www.facebook.com/jeanluc.deganot/'
              className='facebook social'
            >
              <FontAwesomeIcon icon={faFacebook} size='2x' />
            </a>
            <a href='https://twitter.com/jl_ganot' className='twitter social'>
              <FontAwesomeIcon icon={faTwitter} size='2x' />
            </a>
            <a
              href='https://www.instagram.com/inshape_jlg/'
              className='instagram social'
            >
              <FontAwesomeIcon icon={faInstagram} size='2x' />
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <a
              href='https://www.instagram.com/inshape_jlg/'
              className='instagram social'
              target='_blank'
              rel='noopener noreferrer'
            >
              <h2>@inshape_jlg</h2>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
