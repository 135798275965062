import React, { useEffect, useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listCategories } from '../actions/categoryActions'
import { listTags } from '../actions/tagActions'
import TextEditor from './TextEditor'

const BlogCreateForm = ({ submitHandler, blog }) => {
  const blogContentFromLS = () => {
    if (typeof window === 'undefined') {
      return false
    }

    if (localStorage.getItem('blog')) {
      return JSON.parse(localStorage.getItem('blog'))
    } else {
      return false
    }
  }

  const [title, setTitle] = useState((blog && blog.title) || '')
  const [content, setContent] = useState(
    (blog && blog.content) || blogContentFromLS
  )
  const [isPublished, setIsPublished] = useState(
    (blog && blog.isPublished) || false
  )
  const [checkedCategories, setCheckedCategories] = useState(
    (blog && blog.categories) || []
  )
  const [checkedTags, setCheckedTags] = useState((blog && blog.tags) || [])

  const categoryList = useSelector((state) => state.categoryList)
  const { categories } = categoryList

  const tagList = useSelector((state) => state.tagList)
  const { tags } = tagList

  const dispatch = useDispatch()
  useEffect(() => {
    if (!categories) {
      dispatch(listCategories())
    }
    if (!tags) {
      dispatch(listTags())
    }
  }, [dispatch, tags, categories])

  const onSubmitHandler = (e) => {
    e.preventDefault()
    submitHandler &&
      submitHandler({
        title,
        content,
        isPublished,
        categories: checkedCategories,
        tags: checkedTags,
      })
  }

  const handleContent = (content) => {
    setContent(content)
    if (typeof window !== 'undefined') {
      localStorage.setItem('blog', JSON.stringify(content))
    }
  }

  const handleCategoryToggle = (c) => {
    const clickedCategory = checkedCategories.indexOf(c)
    const all = [...checkedCategories]
    if (clickedCategory === -1) {
      all.push(c)
    } else {
      all.splice(clickedCategory, 1)
    }
    setCheckedCategories(all)
  }

  const handleTagToggle = (c) => {
    const clickedCategory = checkedTags.indexOf(c)
    const all = [...checkedTags]
    if (clickedCategory === -1) {
      all.push(c)
    } else {
      all.splice(clickedCategory, 1)
    }
    setCheckedTags(all)
  }

  return (
    <Row>
      <Col md={8}>
        <Form onSubmit={onSubmitHandler}>
          <Form.Group controlId='title'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='content'>
            <TextEditor content={content} setContent={handleContent} />
          </Form.Group>

          <Form.Group controlId='isPublished'>
            <Form.Check
              type='checkbox'
              label='Is Published'
              checked={isPublished}
              onChange={(e) => setIsPublished(e.target.checked)}
            ></Form.Check>
          </Form.Group>

          <Button type='submit' variant='primary'>
            Update
          </Button>
        </Form>
      </Col>
      <Col md={4}>
        <div>
          <h5>Categories</h5>
          <ul style={{ maxHeight: '200px', overflow: 'scroll' }}>
            {categories &&
              categories.map((c, i) => (
                <li key={i} className='list-unstyled'>
                  <Form.Check
                    className='mr-2'
                    type='checkbox'
                    label={c.name}
                    checked={checkedCategories.indexOf(c.name) !== -1}
                    onChange={() => handleCategoryToggle(c.name)}
                  ></Form.Check>
                </li>
              ))}
          </ul>
          <hr />
        </div>
        <div>
          <h5>Tags</h5>
          <ul style={{ maxHeight: '200px', overflow: 'scroll' }}>
            {tags &&
              tags.map((c, i) => (
                <li key={i} className='list-unstyled'>
                  <Form.Check
                    className='mr-2'
                    type='checkbox'
                    label={c.name}
                    onChange={() => handleTagToggle(c.name)}
                    checked={checkedTags.indexOf(c.name) !== -1}
                  ></Form.Check>
                </li>
              ))}
          </ul>
          <hr />
        </div>
      </Col>
    </Row>
  )
}

export default BlogCreateForm
