import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Image, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  createProduct,
  listProductDetails,
  updateProduct,
  uploadProductImage,
} from '../actions/productActions'
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DETAILS_RESET,
  PRODUCT_UPDATE_RESET,
} from '../constants/productConstants'
import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'
import withAuthentication from '../firebase/withAuthentication'

const ProductEditScreen = ({ history, match }) => {
  const productId = match.params.id

  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [isPublished, setIsPublished] = useState(false)
  const [minQty, setMinQty] = useState(0)
  const [price, setPrice] = useState(0)
  const [unit, setUnit] = useState('')
  const [image, setImage] = useState('')
  const [choices, setChoices] = useState([])
  const [validationError, setValidationError] = useState(null)

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  const productImageUpload = useSelector((state) => state.productImageUpload)
  const {
    loading: loadingProductImageUpload,
    error: errorProductImageUpload,
  } = productImageUpload

  const productCreate = useSelector((state) => state.productCreate)
  const {
    error: errorCreate,
    success: successCreate,
  } = productCreate

  useEffect(() => {
    if (successUpdate || successCreate) {
      history.push('/admin/productlist')
      dispatch({ type: PRODUCT_CREATE_RESET })
      dispatch({ type: PRODUCT_UPDATE_RESET })
      dispatch({ type: PRODUCT_DETAILS_RESET })
    }
    else if (match.params.id === 'newProduct') {
    }
    else if (!product || product._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id))
    } else {
      setName(product.name)
      setCategory(product.category)
      setDescription(product.description || '')
      setIsPublished(product.published)
      setMinQty(product.unit_min)
      setPrice(product.unit_price)
      setUnit(product.unit)
      setImage(product.image)
      setChoices(product.choices)
    }
  }, [dispatch, match, product, successUpdate, successCreate, history])

  //Validate Inputs
  useEffect(() => {
    if (category === '') {
      setValidationError('Choose a category.')
    } else if (name.trim() === '') {
      setValidationError('Add a name.')
    } else if (description.trim() === '') {
      setValidationError('Add a description.')
    } else if (minQty < 0) {
      setValidationError('Min must be > 0')
    } else if (choices.length < 1) {
      setValidationError('Add a choices.')
    } else {
      setValidationError(null)
    }
  }, [category, name, description, minQty, choices])

  const handleImageAsFile = (e) => {
    const image = e.target.files[0]
    dispatch(uploadProductImage(image))
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const updatedProduct = {
      name,
      category,
      description,
      published: isPublished,
      unit_min: 1 * minQty,
      unit_price: 1 * price,
      image,
      unit,
      choices,
    }

    if (!product)
      dispatch(createProduct(updatedProduct))
    else
      dispatch(updateProduct(productId, updatedProduct))
  }

  const handleOptionChange = (i, option) => {
    choices[i].qty = option.qty
    choices[i].discount = option.discount
    setChoices(choices)
  }

  return (
    <>
      <Meta title={'Edit Product'} />
      <Link to='/admin/productlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader></Loader>}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
        {validationError && (
          <Message variant='danger'>{validationError}</Message>
        )}
        {loading && <Loader>Loading...</Loader>}
        {error && <Message variant='danger'>{error}</Message>}
        {(product || match.params.id === 'newProduct') && (
          <Form onSubmit={submitHandler}>
            <Form.Row>
              <Form.Group as={Col} controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='name'
                  placeholder='Enter Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId='category'>
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as='select'
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value=''>Choose...</option>
                  <option value='plan'>Plan</option>
                  <option value='challenge'>Challenge</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId='unit'>
                <Form.Label>Unit</Form.Label>
                <Form.Control
                  as='select'
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  <option value=''>Choose...</option>
                  <option value='sessions'>Sessions</option>
                  <option value='weeks'>Weeks</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId='price'>
                <Form.Label>Price Per Unit</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Unit Price'
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Group>
            </Form.Row>

            <Row className='align-items-center'>
              <Col className='text-center'>
                <Button
                  className='my-3'
                  variant='success'
                  onClick={() => setChoices([...choices, {}])}
                >
                  <i className='fas fa-plus'> Add Option</i>
                </Button>
              </Col>
              <Col className='text-center'>
                <Button
                  className='my-3'
                  variant='danger'
                  onClick={() => setChoices([])}
                >
                  <i className='fas fa-trash'> Reset Options</i>
                </Button>
              </Col>
            </Row>

            {choices.map((option, i) => (
              <Form.Row key={i}>
                <Form.Label column sm='1'>
                  {i + 1}:
                </Form.Label>
                <Form.Group as={Col} controlId={`option-${i}-qty`}>
                  <Form.Control
                    type='number'
                    placeholder={option.qty || 'Quantity'}
                    onChange={(e) =>
                      handleOptionChange(i, {
                        qty: 1 * e.target.value,
                        discount: option.discount,
                      })
                    }
                  />
                </Form.Group>
                <Form.Label column sm='2'>
                  {unit}
                </Form.Label>
                <Form.Group as={Col} controlId={`option-${i}-discount`}>
                  <Form.Control
                    type='number'
                    placeholder={option.discount || 'Discount'}
                    onChange={(e) =>
                      handleOptionChange(i, {
                        qty: option.qty,
                        discount: 1 * e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Label column sm='1'>
                  %
                </Form.Label>
              </Form.Row>
            ))}

            <Form.Group controlId='isPublished'>
              <Form.Check
                type='checkbox'
                label='Is Published'
                checked={isPublished}
                onChange={(e) => setIsPublished(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Button variant='primary' type='submit' disabled={validationError}>
              Update
            </Button>
          </Form>
        )}

        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <h2>Image</h2>
        {product && <Image src={image} alt={product.name} fluid />}
        {loadingProductImageUpload ? (
          <Loader></Loader>
        ) : errorProductImageUpload ? (
          <Message variant='danger'>{errorProductImageUpload}</Message>
        ) : (
              <Form>
                <Form.Group controlId='image'>
                  <Form.File
                    id='image-file'
                    label='Choose File'
                    custom
                    onChange={handleImageAsFile}
                  ></Form.File>
                </Form.Group>
              </Form>
            )}
      </FormContainer>
    </>
  )
}

export default withAuthentication(ProductEditScreen)
