import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import BlogHomeScreen from './screens/BlogHomeScreen'
import BlogScreen from './screens/BlogScreen'
import BlogListScreen from './screens/BlogListScreen'
import BlogEditScreen from './screens/BlogEditScreen'
import OrderAssessmentEditScreen from './screens/OrderAssessmentEditScreen'
import ErrorBoundary from './components/ErrorBoundary'
import { Container } from 'react-bootstrap'
import BlogNewScreen from './screens/BlogNewScreen'

const App = () => {
  return (
    <Router>
      <Header></Header>
      <ErrorBoundary>
        <main>
          <Route path='/' component={HomeScreen} exact />
          <Container>
            <Route path='/admin/bloglist' component={BlogListScreen} />
            <Route path='/admin/blog/:id/edit' component={BlogEditScreen} />
            <Route path='/admin/blog/new' component={BlogNewScreen} exact />
            <Route path='/admin/orderlist' component={OrderListScreen} />
            <Route
              path='/admin/product/:id/edit'
              component={ProductEditScreen}
            />
            <Route path='/admin/productlist' component={ProductListScreen} />
            <Route path='/admin/user/:id/edit' component={UserEditScreen} />
            <Route path='/admin/userlist' component={UserListScreen} />
            <Route path='/cart/:id?' component={CartScreen} />
            <Route path='/login' component={LoginScreen} />
            <Route
              path='/assessment/:orderId/:assessmentId'
              component={OrderAssessmentEditScreen}
              exact
            />
            <Route path='/order/:id' component={OrderScreen} />
            <Route path='/payment' component={PaymentScreen} />
            <Route path='/placeorder' component={PlaceOrderScreen} />
            <Route path='/product/:id' component={ProductScreen} />
            <Route path='/profile' component={ProfileScreen} />
            <Route path='/register' component={RegisterScreen} />
            <Route path='/search/:keyword' component={HomeScreen} exact />
            <Route path='/blogs' component={BlogHomeScreen} />
            <Route path='/shipping' component={ShippingScreen} />
            <Route path='/blog/:id' component={BlogScreen} />
          </Container>
        </main>
      </ErrorBoundary>
      <Footer></Footer>
    </Router>
  )
}

export default App
