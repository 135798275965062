import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const languages = ['en', 'fr', 'es', 'ht']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,

    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    // saveMissing: true,
    // saveMissingTo: 'all',

    // backend: {
    //   loadPath: '/locales/{{lng}}/{{ns}}.json',
    //   addPath: 'http://localhost:5000/locales/add/{{lng}}/{{ns}}',
    // },
    whitelist: languages,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
