export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAILED = 'PRODUCT_LIST_FAILED'
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAILED = 'PRODUCT_DETAILS_FAILED'
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAILED = 'PRODUCT_DELETE_FAILED'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAILED = 'PRODUCT_UPDATE_FAILED'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAILED = 'PRODUCT_CREATE_FAILED'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_IMAGE_UPLOAD_REQUEST = 'PRODUCT_IMAGE_UPLOAD_REQUEST'
export const PRODUCT_IMAGE_UPLOAD_SUCCESS = 'PRODUCT_IMAGE_UPLOAD_SUCCESS'
export const PRODUCT_IMAGE_UPLOAD_FAILED = 'PRODUCT_IMAGE_UPLOAD_FAILED'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAILED = 'PRODUCT_CREATE_REVIEW_FAILED'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAILED = 'PRODUCT_TOP_FAILED'

export const PRODUCT_ITEMS_REQUEST = 'PRODUCT_ITEMS_REQUEST'
export const PRODUCT_ITEMS_SUCCESS = 'PRODUCT_ITEMS_SUCCESS'
export const PRODUCT_ITEMS_FAILED = 'PRODUCT_ITEMS_FAILED'
export const PRODUCT_ITEMS_RESET = 'PRODUCT_ITEMS_RESET'

export const PRODUCT_LIST_PUBLISHED_REQUEST = 'PRODUCT_LIST_PUBLISHED_REQUEST'
export const PRODUCT_LIST_PUBLISHED_SUCCESS = 'PRODUCT_LIST_PUBLISHED_SUCCESS'
export const PRODUCT_LIST_PUBLISHED_FAILED = 'PRODUCT_LIST_PUBLISHED_FAILED'
export const PRODUCT_LIST_PUBLISHED_RESET = 'PRODUCT_LIST_PUBLISHED_RESET'

export const PRODUCT_ITEM_DELETE_REQUEST = 'PRODUCT_ITEM_DELETE_REQUEST'
export const PRODUCT_ITEM_DELETE_SUCCESS = 'PRODUCT_ITEM_DELETE_SUCCESS'
export const PRODUCT_ITEM_DELETE_FAILED = 'PRODUCT_ITEM_DELETE_FAILED'
export const PRODUCT_ITEM_DELETE_RESET = 'PRODUCT_ITEM_DELETE_RESET'

export const PRODUCT_ITEM_UPDATE_REQUEST = 'PRODUCT_ITEM_UPDATE_REQUEST'
export const PRODUCT_ITEM_UPDATE_SUCCESS = 'PRODUCT_ITEM_UPDATE_SUCCESS'
export const PRODUCT_ITEM_UPDATE_FAILED = 'PRODUCT_ITEM_UPDATE_FAILED'
export const PRODUCT_ITEM_UPDATE_RESET = 'PRODUCT_ITEM_UPDATE_RESET'

export const PRODUCT_ITEM_CREATE_REQUEST = 'PRODUCT_ITEM_CREATE_REQUEST'
export const PRODUCT_ITEM_CREATE_SUCCESS = 'PRODUCT_ITEM_CREATE_SUCCESS'
export const PRODUCT_ITEM_CREATE_FAILED = 'PRODUCT_ITEM_CREATE_FAILED'
export const PRODUCT_ITEM_CREATE_RESET = 'PRODUCT_ITEM_CREATE_RESET'
