import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const INITIAL_STATE = {
  error: '',
  errorInfo: '',
  hasError: false,
}
class ErrorBoundary extends React.Component {
  state = INITIAL_STATE

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo })
    this.setState({ errorInfo })
  }

  manualPaymentHandler = () => {
    this.setState(INITIAL_STATE)
    this.props.history.push('/')
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <p>
          Something went wrong. Please{' '}
          <span
            style={{ cursor: 'pointer', color: '#0077FF' }}
            onClick={this.manualPaymentHandler}
          >
            go back
          </span>
          .
        </p>
      )
    }
    return this.props.children
  }
}

export default withRouter(ErrorBoundary)

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
