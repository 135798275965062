import React from 'react'
import { Button } from 'react-bootstrap'
import parse from 'html-react-parser'
import moment from 'moment'

const Blogs = ({ blogs, onClick }) => {
  const showBlogCategories = (blog) =>
    blog &&
    blog.categories.map((c, i) => (
      <Button key={i} variant='primary' className='mr-1 ml-1 mt-3'>
        {c}
      </Button>
    ))

  const showBlogTags = (blog) =>
    blog &&
    blog.tags.map((t, i) => (
      <Button key={i} variant='outline-primary' className='mr-1 ml-1 mt-3'>
        {t}
      </Button>
    ))

  const cols =
    blogs &&
    blogs.map((p) => (
      <div className='box' key={p._id}>
        {showBlogCategories(p)}
        {showBlogTags(p)}
        <div>
          <Button variant='link' onClick={() => onClick(`/blog/${p._id}`)}>
            <h3>{p.title}</h3>
          </Button>
        </div>

        <p className='mark ml-1 pt-2 pb-2'>
          By {p.postedBy.email} | Published{' '}
          {moment(new Date(p.updatedAt._seconds * 1000)).fromNow()}
        </p>
        <div>{parse(p.excerpt)}</div>
        <Button
          variant='outline-primary'
          onClick={() => onClick(`/blog/${p._id}`)}
        >
          Read More
        </Button>
      </div>
    ))
  return (
    <div id='blogs' className='blogs'>
      <h1>Blogs</h1>
      {cols}
    </div>
  )
}

export default Blogs
