import axios from 'axios'
import {
  PRODUCT_CREATE_FAILED,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_REVIEW_FAILED,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE_FAILED,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DETAILS_FAILED,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_IMAGE_UPLOAD_FAILED,
  PRODUCT_IMAGE_UPLOAD_REQUEST,
  PRODUCT_IMAGE_UPLOAD_SUCCESS,
  PRODUCT_ITEMS_FAILED,
  PRODUCT_ITEMS_REQUEST,
  PRODUCT_ITEMS_RESET,
  PRODUCT_ITEMS_SUCCESS,
  PRODUCT_ITEM_CREATE_FAILED,
  PRODUCT_ITEM_CREATE_REQUEST,
  PRODUCT_ITEM_CREATE_SUCCESS,
  PRODUCT_ITEM_DELETE_FAILED,
  PRODUCT_ITEM_DELETE_REQUEST,
  PRODUCT_ITEM_DELETE_SUCCESS,
  PRODUCT_ITEM_UPDATE_FAILED,
  PRODUCT_ITEM_UPDATE_REQUEST,
  PRODUCT_ITEM_UPDATE_SUCCESS,
  PRODUCT_LIST_FAILED,
  PRODUCT_LIST_PUBLISHED_FAILED,
  PRODUCT_LIST_PUBLISHED_REQUEST,
  PRODUCT_LIST_PUBLISHED_SUCCESS,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_RESET,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_TOP_FAILED,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_UPDATE_FAILED,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
} from '../constants/productConstants'
import firebase from '../firebase/firebase'

export const listPublichedProducts = (keyword = '') => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_PUBLISHED_REQUEST })

    const { data } = await axios.get(
      `/api/products/published?keyword=${keyword}`
    )

    dispatch({ type: PRODUCT_LIST_PUBLISHED_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_PUBLISHED_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProducts = (keyword = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/products?keyword=${keyword}`, config)

    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductItems = (productId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_ITEMS_REQUEST })

    const { data } = await axios.get(`/api/products/${productId}/items`)

    dispatch({ type: PRODUCT_ITEMS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_ITEMS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/products/${id}`)

    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/products/${id}`, config)

    dispatch({ type: PRODUCT_DELETE_SUCCESS })
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createProduct = (newProduct) => async (dispatch, getState) => {
  try {
    console.log(JSON.stringify(newProduct))
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/products`, newProduct, config)

    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProduct = (id, product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/products/${id}`, product, config)
    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: PRODUCT_LIST_RESET })
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createProductReview = (productId, review) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/products/${productId}/reviews`, review, config)

    dispatch({
      type: PRODUCT_CREATE_REVIEW_SUCCESS,
    })
    // dispatch({ type: PRODUCT_LIST_RESET })
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listTopProducts = (keyword = '') => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TOP_REQUEST })

    const { data } = await axios.get('/api/products/top')

    dispatch({ type: PRODUCT_TOP_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_TOP_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const uploadProductImage = (file) => async (dispatch) => {
  // https://firebase.google.com/docs/storage/web/upload-files
  dispatch({ type: PRODUCT_IMAGE_UPLOAD_REQUEST })
  const metadata = {
    contentType: 'image/jpeg',
  }

  const uploadTask = firebase.storage
    .ref(`/images/${file.name}`)
    .put(file, metadata)

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log('Upload is ' + progress + '% done')
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors

      dispatch({
        type: PRODUCT_IMAGE_UPLOAD_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at', downloadURL)
        dispatch({ type: PRODUCT_IMAGE_UPLOAD_SUCCESS, payload: downloadURL })
      })
    }
  )
}

export const deleteProductItem = (id, pid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_ITEM_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/products/${id}/items/${pid}`, config)

    dispatch({ type: PRODUCT_ITEM_DELETE_SUCCESS })
    dispatch({ type: PRODUCT_ITEMS_RESET })
  } catch (error) {
    dispatch({
      type: PRODUCT_ITEM_DELETE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createProductItem = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_ITEM_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/products/${id}/items`, {}, config)

    dispatch({
      type: PRODUCT_ITEM_CREATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: PRODUCT_ITEMS_RESET })
  } catch (error) {
    dispatch({
      type: PRODUCT_ITEM_CREATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProductItem = (id, pid, product) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PRODUCT_ITEM_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/products/${id}/items/${pid}`,
      product,
      config
    )
    dispatch({
      type: PRODUCT_ITEM_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: PRODUCT_ITEMS_RESET })
  } catch (error) {
    dispatch({
      type: PRODUCT_ITEM_UPDATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
