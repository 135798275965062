import {
  CART_ADD_ITEM,
  CART_ADD_PLAN,
  CART_CLEAR,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from '../constants/cartConstants'

export const addPlanToCart = (plan) => async (dispatch) => {
  dispatch({
    type: CART_ADD_PLAN,
    payload: [plan],
  })

  localStorage.setItem('cartItems', JSON.stringify([plan]))
}

export const addToCart = (item, qty) => async (dispatch, getState) => {
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: item._id,
      ...item,
      qty,
    },
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })

  localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  localStorage.setItem('paymentMethod', JSON.stringify(data))
}

export const clearCart = () => (dispatch) => {
  dispatch({
    type: CART_CLEAR,
  })

  localStorage.removeItem('cartItems')
}
