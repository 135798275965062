import axios from 'axios'
import {
  USER_DETAILS_FAILED,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAILED,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAILED,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILED,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  USER_LINK_GOOGLE_REQUEST,
  USER_LINK_GOOGLE_SUCCESS,
  USER_LINK_GOOGLE_FAILED,
} from '../constants/userConstants'

import {
  ORDER_LIST_MY_RESET,
  ORDER_LIST_RESET,
} from '../constants/orderConstants'

import firebase from '../firebase/firebase'
import { AUTH_TOKEN_VALIDATE_RESET } from '../constants/authConstants'
import { CART_CLEAR } from '../constants/cartConstants'

export const loginWithEmailAndPassword = (email, password) => async (
  dispatch
) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    firebase.auth
      .signInWithEmailAndPassword(email, password)
      .then(async (data) => {
        const { user } = data
        if (user) {
          const token = await user.getIdToken(true)

          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }

          const { data } = await axios.post('/api/auth/signin', {}, config)

          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
          })

          localStorage.setItem('userInfo', JSON.stringify(data))
        }
      })
      .catch((error) => {
        dispatch({
          type: USER_LOGIN_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      })
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const loginWithGoogle = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const firebaseUser = await firebase.auth
      .signInWithPopup(firebase.googleProvider)
      .catch((error) => {
        dispatch({
          type: USER_LOGIN_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      })

    if (firebaseUser.user) {
      const user = firebaseUser.user
      const token = await user.getIdToken(true)

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const { data } = await axios.post('/api/auth/signin', {}, config)

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const loginWithFacebook = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const firebaseUser = await firebase.auth
      .signInWithPopup(firebase.facebookProvider)
      .catch((error) => {
        dispatch({
          type: USER_LOGIN_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      })

    if (firebaseUser.user) {
      const user = firebaseUser.user
      const token = await user.getIdToken(true)

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const { data } = await axios.post('/api/auth/signin', {}, config)

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const loginWithTwitter = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const firebaseUser = await firebase.auth
      .signInWithPopup(firebase.twitterProvider)
      .catch((error) => {
        dispatch({
          type: USER_LOGIN_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      })

    if (firebaseUser.user) {
      const user = firebaseUser.user
      const token = await user.getIdToken(true)

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const { data } = await axios.post('/api/auth/signin', {}, config)

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const linkGoogleAccount = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LINK_GOOGLE_REQUEST,
    })

    firebase.auth.currentUser
      .linkWithPopup(firebase.googleProvider)
      .then(async (result) => {
        const { user } = result

        if (user) {
          dispatch({
            type: USER_LINK_GOOGLE_SUCCESS,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: USER_LINK_GOOGLE_FAILED,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      })
  } catch (error) {
    dispatch({
      type: USER_LINK_GOOGLE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = () => (dispatch) => {
  firebase.auth.signOut()
  localStorage.removeItem('userInfo')
  localStorage.removeItem('cartItems')
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_LIST_RESET })
  dispatch({ type: USER_DETAILS_RESET })
  dispatch({ type: ORDER_LIST_MY_RESET })
  dispatch({ type: ORDER_LIST_RESET })
  dispatch({ type: AUTH_TOKEN_VALIDATE_RESET })
  dispatch({ type: CART_CLEAR })
}

export const registerWithEmailAndPassword = (name, email, password) => async (
  dispatch
) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    })

    await firebase.auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (data) => {
        const { user } = data
        if (user) {
          console.log(JSON.stringify(user))
          const token = await user.getIdToken(true)

          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }

          const { data } = await axios.post('/api/auth/signin', {}, config)

          dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data,
          })

          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
          })

          localStorage.setItem('userInfo', JSON.stringify(data))
        }
      })
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/users/${id}`, config)

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/users/profile`, user, config)

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })
    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/users`, config)

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_LIST_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/users/${id}`, config)

    dispatch({ type: USER_DELETE_SUCCESS })
    dispatch({ type: USER_LIST_RESET })
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUser = (id, user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/users/${id}`, user, config)

    dispatch({
      type: USER_UPDATE_SUCCESS,
    })
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
