import React from 'react'
import PropTypes from 'prop-types'
import { Col, Form } from 'react-bootstrap'

const SurveyText = (props) => {
  return (
    <Form.Group controlId={props.block.name}>
      <Form.Label>{props.block.title}</Form.Label>
      <Form.Control
        required={props.block.isRequired || false}
        type={props.block.inputType}
        onChange={(e) =>
          props.block.onChange(props.block.name, 1 * e.target.value)
        }
        value={props.block.data ? props.block.data[props.block.name] || '' : ''}
      />
    </Form.Group>
  )
}

SurveyText.propTypes = {
  block: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputType: PropTypes.string.isRequired,
  }).isRequired,
}

const SurveyRadio = (props) => {
  const choices = props.block.choices.map((choice) => (
    <Form.Check
      required={props.block.isRequired || false}
      type='radio'
      label={choice}
      key={choice}
      name={props.block.name}
      id={choice}
      onChange={(e) => props.block.onChange(props.block.name, choice)}
      checked={
        props.block.data && props.block.data[props.block.name] === choice
      }
    />
  ))
  return (
    <Form.Group controlId={props.block.name}>
      <Form.Label>{props.block.title}</Form.Label>
      {choices}
    </Form.Group>
  )
}

const SurveyMetric = (props) => {
  if (props.block.unit === 'lb') {
    return (
      <Form.Group controlId={props.block.name}>
        <Form.Label>{props.block.title}</Form.Label>
        <Form.Control
          required={props.block.isRequired || false}
          type='number'
          placeholder={props.block.unit}
          onChange={(e) =>
            props.block.onChange(props.block.name, {
              unit: props.block.unit,
              value: 1 * e.target.value,
            })
          }
          value={
            (props.block.data &&
              props.block.data[props.block.name] &&
              props.block.data[props.block.name]['value']) ||
            ''
          }
        />
      </Form.Group>
    )
  } else if (props.block.unit === 'ft') {
    return (
      <Form.Group controlId={props.block.name}>
        <Form.Label>{props.block.title}</Form.Label>
        <Form.Row>
          <Col>
            <Form.Control
              required={props.block.isRequired || false}
              type='number'
              placeholder='ft'
              onChange={(e) =>
                props.block.onChange(props.block.name, {
                  unit: props.block.unit,
                  value: { ft: 1 * e.target.value },
                })
              }
              value={
                (props.block.data &&
                  props.block.data[props.block.name] &&
                  props.block.data[props.block.name]['value'] &&
                  props.block.data[props.block.name]['value']['ft']) ||
                ''
              }
            />
          </Col>
          <Col>
            <Form.Control
              required={props.block.isRequired || false}
              type='number'
              placeholder='in'
              onChange={(e) =>
                props.block.onChange(props.block.name, {
                  unit: props.block.unit,
                  value: { in: 1 * e.target.value },
                })
              }
              value={
                (props.block.data &&
                  props.block.data[props.block.name] &&
                  props.block.data[props.block.name]['value'] &&
                  props.block.data[props.block.name]['value']['in']) ||
                ''
              }
            />
          </Col>
        </Form.Row>
      </Form.Group>
    )
  }
  return null
}

const SurveyDropdown = (props) => {
  const choices = props.block.choices.map((choice) => (
    <option key={choice.value} value={choice.value}>
      {choice.text}
    </option>
  ))
  return (
    <Form.Group controlId={props.block.name}>
      <Form.Label>{props.block.title}</Form.Label>
      <Form.Control
        required={props.block.isRequired || false}
        as='select'
        onChange={(e) =>
          props.block.onChange(props.block.name, 1 * e.target.value)
        }
        value={props.block.data ? props.block.data[props.block.name] : ''}
      >
        <option>Choose...</option>
        {choices}
      </Form.Control>
    </Form.Group>
  )
}

export { SurveyText, SurveyRadio, SurveyMetric, SurveyDropdown }
