import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { authTokenReducer } from './reducers/authReducers'
import {
  blogCreateReducer,
  blogCreateReviewReducer,
  blogDeleteReducer,
  blogDetailsReducer,
  blogImageUploadReducer,
  blogListPublishedReducer,
  blogListReducer,
  blogTopRatedListReducer,
  blogUpdateReducer,
} from './reducers/blogReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  categoryCreateReducer,
  categoryDeleteReducer,
  categoryListReducer,
  categoryUpdateReducer,
} from './reducers/categoryReducers'
import {
  orderAssessmentCreateReducer,
  orderAssessmentDetailsReducer,
  orderAssessmentListReducer,
  orderAssessmentUpdateReducer,
  orderCreateReducer,
  orderDeleteReducer,
  orderDetailsReducer,
  orderListMyReducer,
  orderListReducer,
  orderPayReducer,
  orderUpdateReducer,
} from './reducers/orderReducers'
import {
  productCreateReducer,
  productCreateReviewReducer,
  productDeleteReducer,
  productDetailsReducer,
  productImageUploadReducer,
  productItemCreateReducer,
  productItemDeleteReducer,
  productItemsReducer,
  productItemUpdateReducer,
  productListPublishedReducer,
  productListReducer,
  productTopRatedListReducer,
  productUpdateReducer,
} from './reducers/productReducers'
import {
  tagCreateReducer,
  tagDeleteReducer,
  tagListReducer,
  tagUpdateReducer,
} from './reducers/tagReducers'
import {
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
} from './reducers/userReducers'

import { macroCalculateReducer } from './reducers/macroReducers'

import { contactFormReducer } from './reducers/contactReducers'
const reducer = combineReducers({
  authToken: authTokenReducer,
  blogCreate: blogCreateReducer,
  blogCreateReview: blogCreateReviewReducer,
  blogDelete: blogDeleteReducer,
  blogDetails: blogDetailsReducer,
  blogImageUpload: blogImageUploadReducer,
  blogList: blogListReducer,
  blogListPublished: blogListPublishedReducer,
  blogTopRatedList: blogTopRatedListReducer,
  blogUpdate: blogUpdateReducer,
  cart: cartReducer,
  categoryCreate: categoryCreateReducer,
  categoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryList: categoryListReducer,
  contactForm: contactFormReducer,
  macroCalculate: macroCalculateReducer,
  orderAssessmentCreate: orderAssessmentCreateReducer,
  orderAssessmentDetails: orderAssessmentDetailsReducer,
  orderAssessmentList: orderAssessmentListReducer,
  orderAssessmentUpdate: orderAssessmentUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDelete: orderDeleteReducer,
  orderDetails: orderDetailsReducer,
  orderList: orderListReducer,
  orderListMy: orderListMyReducer,
  orderPay: orderPayReducer,
  orderUpdate: orderUpdateReducer,
  productCreate: productCreateReducer,
  productCreateReview: productCreateReviewReducer,
  productDelete: productDeleteReducer,
  productDetails: productDetailsReducer,
  productImageUpload: productImageUploadReducer,
  productItems: productItemsReducer,
  productItemCreate: productItemCreateReducer,
  productItemUpdate: productItemUpdateReducer,
  productItemDelete: productItemDeleteReducer,
  productList: productListReducer,
  productListPublished: productListPublishedReducer,
  productTopRatedList: productTopRatedListReducer,
  productUpdate: productUpdateReducer,
  tagCreate: tagCreateReducer,
  tagDelete: tagDeleteReducer,
  tagUpdate: tagUpdateReducer,
  tagList: tagListReducer,
  userDelete: userDeleteReducer,
  userDetails: userDetailsReducer,
  userList: userListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userUpdateProfile: userUpdateProfileReducer,
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

const paymentMethodFromStorage = localStorage.getItem('paymentMethod')
  ? JSON.parse(localStorage.getItem('paymentMethod'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: paymentMethodFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
