import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { calculateMacro } from '../../actions/macroActions'
import MaterialTable from 'material-table'
import Breadcrumb from '../Breadcrumb/Breadcrumb'
import './MacroCalculator.css'
import MacroForm from '../MacroForm/MacroForm'

const MacroCalculator = () => {
  const [showMacroForm, setShowMacroForm] = useState(false)
  const handleCloseMacroForm = () => setShowMacroForm(false)
  const handleShowMacroForm = () => setShowMacroForm(true)

  const columnsCalories = [
    {
      title: 'Activity',
      field: 'text',
    },
    {
      title: 'Calories',
      field: 'calories',
      render: (rowData) =>
        `${rowData.calories.toLocaleString()} calories per day`,
    },
  ]

  const columnsMacros = [
    {
      title: 'Breakdown',
      field: 'text',
    },
    {
      title: 'Protein (g)',
      field: 'protein',
    },
    {
      title: 'Carbs (g)',
      field: 'carbs',
    },
    {
      title: 'Fats (g)',
      field: 'fats',
    },
  ]

  const [selected, setSelected] = useState({ selectedCrumb: 'maintenace' })

  const { selectedCrumb } = selected

  const dispatch = useDispatch()

  const macroCalculate = useSelector((state) => state.macroCalculate)
  const { macros } = macroCalculate

  const onSelect = (crumb) => {
    setSelected({ selectedCrumb: crumb })
  }

  return (
    <div>
      <Container>
        <Row className='align-items-center'>
          <Col>
            <h1>Macro Calculator</h1>
          </Col>
          <Col className='text-right'>
            <Button className='mb-1' onClick={handleShowMacroForm}>
              Click to get started!
            </Button>
          </Col>
        </Row>
        <MacroForm
          show={showMacroForm}
          handleClose={handleCloseMacroForm}
          handleSave={(answers) => {
            dispatch(calculateMacro(answers))
            handleCloseMacroForm()
          }}
        />
        {macros && (
          <>
            <Row>
              <Col md={4}>
                <h2>Your Maintenance Calories</h2>
                <div className='maintenance'>
                  <h3>{macros.current_calories.toLocaleString()}</h3>
                  <p>calories per day</p>
                  <hr />
                  <h3>{(macros.current_calories * 7).toLocaleString()}</h3>
                  <p>calories per week</p>
                </div>
              </Col>
              <Col>
                <MaterialTable
                  title=''
                  data={macros.calories_by_activity}
                  columns={columnsCalories}
                  options={{
                    search: false,
                    paging: false,
                    header: false,
                  }}
                />
              </Col>
            </Row>

            <h2>Macros</h2>

            <Breadcrumb
              crumbs={['maintenace', 'cutting', 'bulking']}
              onSelect={onSelect}
            />
            <MaterialTable
              title={`${macros.macros_by_split[selectedCrumb][0][
                'calories'
              ].toLocaleString()} calories per day`}
              data={macros.macros_by_split[selectedCrumb]}
              columns={columnsMacros}
              options={{
                search: false,
                paging: false,
              }}
            />
          </>
        )}
      </Container>
    </div>
  )
}

export default MacroCalculator
