import {
  AUTH_TOKEN_VALIDATE_FAILED,
  AUTH_TOKEN_VALIDATE_REQUEST,
  AUTH_TOKEN_VALIDATE_SUCCESS,
  AUTH_TOKEN_VALIDATE_RESET,
} from '../constants/authConstants'

export const authTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH_TOKEN_VALIDATE_REQUEST:
      return {}
    case AUTH_TOKEN_VALIDATE_SUCCESS:
      return { success: true }
    case AUTH_TOKEN_VALIDATE_FAILED:
      return { error: action.payload }
    case AUTH_TOKEN_VALIDATE_RESET:
      return {}
    default:
      return state
  }
}
