export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAILED = 'CATEGORY_LIST_FAILED'
export const CATEGORY_LIST_RESET = 'CATEGORY_LIST_RESET'

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST'
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_FAILED = 'CATEGORY_DELETE_FAILED'
export const CATEGORY_DELETE_RESET = 'CATEGORY_DELETE_RESET'

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST'
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS'
export const CATEGORY_UPDATE_FAILED = 'CATEGORY_UPDATE_FAILED'
export const CATEGORY_UPDATE_RESET = 'CATEGORY_UPDATE_RESET'

export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST'
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS'
export const CATEGORY_CREATE_FAILED = 'CATEGORY_CREATE_FAILED'
export const CATEGORY_CREATE_RESET = 'CATEGORY_CREATE_RESET'
