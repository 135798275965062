import React, { useEffect, useState } from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'

const TextEditor = ({ content, setContent }) => {
  const [editorState, setEditorState] = useState()

  useEffect(() => {
    if (!editorState) {
      const contentBlock = htmlToDraft(content || '<p></p>')
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        )
        const editorState = EditorState.createWithContent(contentState)
        setEditorState(editorState)
      }
    }
  }, [content, editorState])

  const onEditorStateChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)
    setContent(markup)
    setEditorState(editorState)
  }

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  )
}

export default TextEditor
