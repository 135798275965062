import React from 'react'
import { PayPalButton } from 'react-paypal-button-v2'

const CLIENT = {
  sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  production: process.env.REACT_APP_PAYPAL_CLIENT_ID,
}

const CLIENT_ID =
  process.env.NODE_ENV === 'production' ? CLIENT.production : CLIENT.sandbox

const PayPalPayButton = ({ amount, onSuccess }) => {
  return (
    <PayPalButton
      amount={amount}
      onSuccess={(details, data) => {
        onSuccess(details)
      }}
      options={{
        clientId: CLIENT_ID,
      }}
    />
  )
}

export default PayPalPayButton
