import {
  BLOG_CREATE_COMMENT_FAILED,
  BLOG_CREATE_COMMENT_REQUEST,
  BLOG_CREATE_COMMENT_RESET,
  BLOG_CREATE_COMMENT_SUCCESS,
  BLOG_CREATE_FAILED,
  BLOG_CREATE_REQUEST,
  BLOG_CREATE_RESET,
  BLOG_CREATE_SUCCESS,
  BLOG_DELETE_FAILED,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_SUCCESS,
  BLOG_DETAILS_FAILED,
  BLOG_DETAILS_REQUEST,
  BLOG_DETAILS_RESET,
  BLOG_DETAILS_SUCCESS,
  BLOG_IMAGE_UPLOAD_FAILED,
  BLOG_IMAGE_UPLOAD_REQUEST,
  BLOG_IMAGE_UPLOAD_SUCCESS,
  BLOG_LIST_FAILED,
  BLOG_LIST_PUBLISHED_FAILED,
  BLOG_LIST_PUBLISHED_REQUEST,
  BLOG_LIST_PUBLISHED_SUCCESS,
  BLOG_LIST_REQUEST,
  BLOG_LIST_RESET,
  BLOG_LIST_SUCCESS,
  BLOG_TOP_FAILED,
  BLOG_TOP_REQUEST,
  BLOG_TOP_SUCCESS,
  BLOG_UPDATE_FAILED,
  BLOG_UPDATE_REQUEST,
  BLOG_UPDATE_RESET,
  BLOG_UPDATE_SUCCESS,
} from '../constants/blogConstants'

export const blogListReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_LIST_REQUEST:
      return { loading: true }
    case BLOG_LIST_SUCCESS:
      return { loading: false, blogs: action.payload }
    case BLOG_LIST_FAILED:
      return { loading: false, error: action.payload }
    case BLOG_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const blogListPublishedReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case BLOG_LIST_PUBLISHED_REQUEST:
      return { loading: true, blogs: [] }
    case BLOG_LIST_PUBLISHED_SUCCESS:
      return { loading: false, blogs: action.payload }
    case BLOG_LIST_PUBLISHED_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const blogDetailsReducer = (
  state = { blog: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case BLOG_DETAILS_REQUEST:
      return { loading: true, ...state }
    case BLOG_DETAILS_SUCCESS:
      return { loading: false, blog: action.payload }
    case BLOG_DETAILS_FAILED:
      return { loading: false, error: action.payload }
    case BLOG_DETAILS_RESET:
      return { blog: { reviews: [] } }
    default:
      return state
  }
}

export const blogDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_DELETE_REQUEST:
      return { loading: true }
    case BLOG_DELETE_SUCCESS:
      return { loading: false, success: true }
    case BLOG_DELETE_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const blogUpdateReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case BLOG_UPDATE_REQUEST:
      return { loading: true }
    case BLOG_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case BLOG_UPDATE_FAILED:
      return { loading: false, error: action.payload }
    case BLOG_UPDATE_RESET:
      return { blog: {} }
    default:
      return state
  }
}

export const blogCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_CREATE_REQUEST:
      return { loading: true }
    case BLOG_CREATE_SUCCESS:
      return { loading: false, success: true, blog: action.payload }
    case BLOG_CREATE_FAILED:
      return { loading: false, error: action.payload }
    case BLOG_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const blogImageUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_IMAGE_UPLOAD_REQUEST:
      return { loading: true }
    case BLOG_IMAGE_UPLOAD_SUCCESS:
      return { loading: false, success: true, url: action.payload }
    case BLOG_IMAGE_UPLOAD_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const blogCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_CREATE_COMMENT_REQUEST:
      return { loading: true }
    case BLOG_CREATE_COMMENT_SUCCESS:
      return { loading: false, success: true }
    case BLOG_CREATE_COMMENT_FAILED:
      return { loading: false, error: action.payload }
    case BLOG_CREATE_COMMENT_RESET:
      return {}
    default:
      return state
  }
}

export const blogTopRatedListReducer = (state = { blogs: [] }, action) => {
  switch (action.type) {
    case BLOG_TOP_REQUEST:
      return { loading: true, blogs: [] }
    case BLOG_TOP_SUCCESS:
      return { loading: false, blogs: action.payload }
    case BLOG_TOP_FAILED:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
