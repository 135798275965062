export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAILED = 'ORDER_CREATE_FAILED'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAILED = 'ORDER_DETAILS_FAILED'
export const ORDER_DETAILS_RESET = 'ORDER_DETAILS_RESET'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAILED = 'ORDER_PAY_FAILED'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_LIST_MY_REQUEST = 'ORDER_LIST_MY_REQUEST'
export const ORDER_LIST_MY_SUCCESS = 'ORDER_LIST_MY_SUCCESS'
export const ORDER_LIST_MY_FAILED = 'ORDER_LIST_MY_FAILED'
export const ORDER_LIST_MY_RESET = 'ORDER_LIST_MY_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAILED = 'ORDER_LIST_FAILED'
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET'

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST'
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS'
export const ORDER_DELETE_FAILED = 'ORDER_DELETE_FAILED'

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST'
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS'
export const ORDER_UPDATE_FAILED = 'ORDER_UPDATE_FAILED'
export const ORDER_UPDATE_RESET = 'ORDER_UPDATE_RESET'

export const ORDER_ASSESSMENT_CREATE_REQUEST = 'ORDER_ASSESSMENT_CREATE_REQUEST'
export const ORDER_ASSESSMENT_CREATE_SUCCESS = 'ORDER_ASSESSMENT_CREATE_SUCCESS'
export const ORDER_ASSESSMENT_CREATE_FAILED = 'ORDER_ASSESSMENT_CREATE_FAILED'
export const ORDER_ASSESSMENT_CREATE_RESET = 'ORDER_ASSESSMENT_CREATE_RESET'

export const ORDER_ASSESSMENT_DETAILS_REQUEST =
  'ORDER_ASSESSMENT_DETAILS_REQUEST'
export const ORDER_ASSESSMENT_DETAILS_SUCCESS =
  'ORDER_ASSESSMENT_DETAILS_SUCCESS'
export const ORDER_ASSESSMENT_DETAILS_FAILED = 'ORDER_ASSESSMENT_DETAILS_FAILED'
export const ORDER_ASSESSMENT_DETAILS_RESET = 'ORDER_ASSESSMENT_DETAILS_RESET'

export const ORDER_ASSESSMENT_UPDATE_REQUEST = 'ORDER_ASSESSMENT_UPDATE_REQUEST'
export const ORDER_ASSESSMENT_UPDATE_SUCCESS = 'ORDER_ASSESSMENT_UPDATE_SUCCESS'
export const ORDER_ASSESSMENT_UPDATE_FAILED = 'ORDER_ASSESSMENT_UPDATE_FAILED'
export const ORDER_ASSESSMENT_UPDATE_RESET = 'ORDER_ASSESSMENT_UPDATE_RESET'

export const ORDER_ASSESSMENT_LIST_REQUEST = 'ORDER_ASSESSMENT_LIST_REQUEST'
export const ORDER_ASSESSMENT_LIST_SUCCESS = 'ORDER_ASSESSMENT_LIST_SUCCESS'
export const ORDER_ASSESSMENT_LIST_FAILED = 'ORDER_ASSESSMENT_LIST_FAILED'
export const ORDER_ASSESSMENT_LIST_RESET = 'ORDER_ASSESSMENT_LIST_RESET'
