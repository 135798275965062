import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

const Packages = ({ product, addToCartHandler }) => {
  const cols = product.choices.map((choice, idx) => {
    const units = choice.qty / product.unit_min
    const full_price = choice.qty * product.unit_price
    const discount = choice.discount / 100
    const full_payments = Math.floor(units - units * discount)
    const discounted_full = full_price - full_price * discount
    const balance =
      discounted_full - full_payments * product.unit_price * product.unit_min

    return (
      <Col key={idx} md={6} sm={6} lg={3}>
        <div className='box'>
          <h3>
            {choice.qty}-{product.unit}
          </h3>

          {discount === 0 || full_payments === 0 ? (
            <p>One Time ${Math.round(discounted_full)}</p>
          ) : (
            <>
              <p>
                One Time ${Math.round(discounted_full)} (
                {Math.round(discount * 100)}% off)
              </p>
              <p>Or</p>
              <p>
                {full_payments} x ${product.unit_price * product.unit_min} /
                {product.unit_min} {product.unit}{' '}
                {balance > 0 ? `, then $${Math.round(balance)}` : ''}
              </p>
            </>
          )}

          <Button
            variant='outline-primary'
            onClick={() =>
              addToCartHandler({
                id: idx,
                category: 'Personal Training',
                name: `${choice.qty}-${product.unit}`,
                price: discounted_full,
              })
            }
          >
            Start Today
          </Button>
        </div>
      </Col>
    )
  })

  return (
    <div id='services' className='services'>
      <h1 className='py-5'>Packages</h1>
      <Container>{product && <Row>{cols}</Row>}</Container>
    </div>
  )
}

export default Packages
