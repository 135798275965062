import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listPublishedBlogs } from '../actions/blogActions'
import Blogs from '../components/Blogs'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'
import { BLOG_DETAILS_RESET } from '../constants/blogConstants'

const BlogHomeScreen = ({ history }) => {
  const dispatch = useDispatch()

  const blogListPublished = useSelector((state) => state.blogListPublished)
  const { loading, error, blogs } = blogListPublished

  useEffect(() => {
    dispatch(listPublishedBlogs())
    dispatch({ type: BLOG_DETAILS_RESET })
  }, [dispatch])

  const onClick = (path) => {
    history.push(path)
  }

  return (
    <>
      <Meta title='Blogs' />
      {loading ? (
        <Loader>Loading...</Loader>
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Blogs blogs={blogs} onClick={onClick}></Blogs>
      )}
    </>
  )
}

export default BlogHomeScreen
