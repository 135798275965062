import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { createBlog } from '../actions/blogActions'
import Message from '../components/Message'
import { BLOG_UPDATE_RESET } from '../constants/blogConstants'
import Meta from '../components/Meta'
import withAuthentication from '../firebase/withAuthentication'
import BlogCreateForm from '../components/BlogCreateForm'

const BlogNewScreen = ({ history }) => {
  const dispatch = useDispatch()

  const blogCreate = useSelector((state) => state.blogCreate)
  const { error: errorBlogCreate, success: successBlogCreate } = blogCreate

  useEffect(() => {
    if (successBlogCreate) {
      dispatch({ type: BLOG_UPDATE_RESET })
      history.push('/admin/bloglist')
    }
  }, [dispatch, history, successBlogCreate])

  const submitHandler = (e) => {
    dispatch(createBlog(e))
  }

  return (
    <>
      <Meta title={'Edit Blog'} />
      <Link to='/admin/bloglist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <>
        <h1>New Blog</h1>
        {errorBlogCreate && (
          <Message variant='danger'>{errorBlogCreate}</Message>
        )}
        <BlogCreateForm submitHandler={submitHandler} />
      </>
    </>
  )
}

export default withAuthentication(BlogNewScreen)
