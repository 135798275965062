import {
  MACRO_CALCULATE_REQUEST,
  MACRO_CALCULATE_SUCCESS,
  MACRO_CALCULATE_FAIL,
  MACRO_CALCULATE_RESET,
} from '../constants/macroConstants'

import firebase from '../firebase/firebase'
export const macroCalculateReducer = (state = {}, action) => {
  switch (action.type) {
    case MACRO_CALCULATE_REQUEST:
      return { loading: true }
    case MACRO_CALCULATE_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, macros: action.payload }
    case MACRO_CALCULATE_FAIL:
      return { loading: false, error: action.payload }
    case MACRO_CALCULATE_RESET:
      return {}
    default:
      return state
  }
}
