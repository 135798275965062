import { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  listCategories,
  createCategory,
  deleteCategory,
} from '../actions/categoryActions'
import { createTag, deleteTag, listTags } from '../actions/tagActions'
import Message from './Message'

const Category = () => {
  const { t } = useTranslation(['translation', 'phrases'])
  const [category, setCategory] = useState('')
  const [tag, setTag] = useState('')

  const dispatch = useDispatch()

  const categoryList = useSelector((state) => state.categoryList)
  const { categories } = categoryList

  const categoryCreate = useSelector((state) => state.categoryCreate)
  const { success: successCategoryCreate } = categoryCreate

  const categoryDelete = useSelector((state) => state.categoryDelete)
  const { success: successCategoryDelete } = categoryDelete

  const tagList = useSelector((state) => state.tagList)
  const { tags } = tagList

  const tagCreate = useSelector((state) => state.tagCreate)
  const { success: successTagCreate } = tagCreate

  const tagDelete = useSelector((state) => state.tagDelete)
  const { success: successTagDelete } = tagDelete

  useEffect(() => {
    if (!categories) {
      dispatch(listCategories())
    }
    if (!tags) {
      dispatch(listTags())
    }
  }, [dispatch, categories, tags])

  const onDeleteCategory = (slug) => {
    const answer = window.confirm(
      'Are you sure you want to delete this category?'
    )
    if (answer) {
      dispatch(deleteCategory(slug))
    }
  }

  const onDeleteTag = (slug) => {
    const answer = window.confirm('Are you sure you want to delete this tag?')
    if (answer) {
      dispatch(deleteTag(slug))
    }
  }

  const onSubmitCategory = (e) => {
    e.preventDefault()
    dispatch(createCategory({ name: category }))
    setCategory('')
  }

  const onSubmitTag = (e) => {
    e.preventDefault()
    dispatch(createTag({ name: tag }))
    setTag('')
  }

  const showCategories = () => {
    return (
      categories &&
      categories.map((c, i) => {
        return (
          <Button
            variant='outline-primary'
            onDoubleClick={() => onDeleteCategory(c.slug)}
            title='Double click to delete'
            key={i}
            className='mr-1 ml-1 mt-3'
          >
            {c.name}
          </Button>
        )
      })
    )
  }

  const showTags = () => {
    return (
      tags &&
      tags.map((c, i) => {
        return (
          <Button
            variant='outline-primary'
            onDoubleClick={() => onDeleteTag(c.slug)}
            title='Double click to delete'
            key={i}
            className='mr-1 ml-1 mt-3'
          >
            {c.name}
          </Button>
        )
      })
    )
  }

  const newCategoryFom = () => (
    <Form onSubmit={onSubmitCategory}>
      <Form.Group controlId='category'>
        <Form.Control
          type='text'
          placeholder={t('Enter Category')}
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Button type='submit' variant='primary'>
        {t('Add')}
      </Button>
    </Form>
  )

  const newTagFom = () => (
    <Form onSubmit={onSubmitTag}>
      <Form.Group controlId='tag'>
        <Form.Control
          type='text'
          placeholder={t('Enter Tag')}
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Button type='submit' variant='primary'>
        {t('Add')}
      </Button>
    </Form>
  )

  return (
    <>
      {successCategoryCreate && (
        <Message variant='success'>Category Added.</Message>
      )}
      {successCategoryDelete && (
        <Message variant='info'>Category Deleted.</Message>
      )}
      {successTagCreate && <Message variant='success'>Tag Added.</Message>}
      {successTagDelete && <Message variant='info'>Tag Deleted.</Message>}
      <Row>
        <Col>
          {newCategoryFom()}
          {showCategories()}
        </Col>
        <Col>
          {newTagFom()}
          {showTags()}
        </Col>
      </Row>
    </>
  )
}

export default Category
