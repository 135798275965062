import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listBlogDetails, updateBlog } from '../actions/blogActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { BLOG_UPDATE_RESET } from '../constants/blogConstants'
import Meta from '../components/Meta'
import withAuthentication from '../firebase/withAuthentication'
import BlogCreateForm from '../components/BlogCreateForm'

const BlogEditScreen = ({ history, match }) => {
  const blogId = match.params.id

  const dispatch = useDispatch()

  const blogDetails = useSelector((state) => state.blogDetails)
  const { loading, error, blog } = blogDetails

  const blogUpdate = useSelector((state) => state.blogUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = blogUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: BLOG_UPDATE_RESET })
      history.push('/admin/bloglist')
    } else {
      if (!blog.title || blog._id !== blogId) {
        dispatch(listBlogDetails(blogId))
      }
    }
  }, [dispatch, history, blog, blogId, successUpdate])

  const submitHandler = (e) => {
    dispatch(updateBlog(blogId, e))
  }

  return (
    <>
      <Meta title={'Edit Blog'} />
      <Link to='/admin/bloglist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <>
        <h1>Edit Blog</h1>
        {loadingUpdate && <Loader></Loader>}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader></Loader>
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : blog ? (
          <BlogCreateForm blog={blog} submitHandler={submitHandler} />
        ) : (
          <></>
        )}
      </>
    </>
  )
}

export default withAuthentication(BlogEditScreen)
