import app from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/storage'
import config from './config'

class Firebase {
  constructor() {
    app.initializeApp(config)
    if (process.env.REACT_APP_STAGE === 'development') {
      app.auth().useEmulator('http://localhost:9099/')
    }
    this.auth = app.auth()
    this.storage = app.storage()
    this.googleProvider = new app.auth.GoogleAuthProvider()
    this.facebookProvider = new app.auth.FacebookAuthProvider()
    this.twitterProvider = new app.auth.TwitterAuthProvider()
    this.analytics = app.analytics()
  }
}

const firebase = new Firebase()

export default firebase
